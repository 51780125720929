<template>
    <div>
        <DekstopAlumniStories v-if="!isMobile" />
        <MobileAlumniStories v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DekstopAlumniStories from "@/components/alumni-stories/desktop/AlumniStories.vue"
  import MobileAlumniStories from "@/components/alumni-stories/mobile/AlumniStories.vue"
  
  export default {
    components: {
        DekstopAlumniStories,
        MobileAlumniStories,
    },
    metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.$route.meta.title,
      htmlAttrs: { lang: "id", amp: true },
      meta: [
        { name: "description", content: this.$route.meta.description },
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.$route.meta.title,
            "url": thisPath
          }
        }
      ]
    };
  },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        handleView() {
            this.isMobile = window.innerWidth <= 990;
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.$utils.scrollTop()
    },
    beforeMount() {
        this.handleView();
    },
  };
  </script>
<template>
    <div class="outer-container" style="margin-top: 63px">
        <div class="hero">
            <div class="hero-ctn">
                <h1>Portfolio</h1>
                <p>Berbagai proyek kemitraan dalam transformasi digital berkelanjutan yang telah berhasil HiColleagues
                    selenggarakan</p>
            </div>
        </div>
        <div class="portfolio-container">
            <div class="category-portfolio-container">
                <div v-for="(category, index) in categoryList" :key="index" @click="handleChangeCategory(category.key)" :class="currentCategory == category.key ? 'active' : 'inactive'">
                    {{ category.value }}
                </div>
            </div>
            <div class="card" v-if="projectPortfolio.length > 0">
                <div class="items" v-for="(item, idx) in projectPortfolio" :key="idx"
                    @click="$router.push(`/portfolio/detail/${item.id}`)">
                    <div style="height: 240px">
                        <img :src="item.image_url" :alt="item.program"
                            style="width: 100%; height: 100%; border-radius: 16px 16px 0 0;" />
                    </div>
                    <div class="content">
                        <div class="content-title">
                            <h2>{{ item.program }}</h2>                        
                        </div>
                        <p class="content-title-p">{{item.title}}</p>
                        <p class="description-class">{{ item.description }}</p>
                    </div>
                </div>
            </div>
            <div class="empty-data" v-else>
                <img src="/img/empty_folder.png" alt="">
                <div class="empty-data-text">
                    <h3>Ups, Belum Ada Portfolio Nih!</h3>
                    <p>Halo HiColers! Saat ini belum ada Portfolio yang tersedia. Tunggu informasi selanjutnya. Terima kasih!</p>
                </div>
            </div>
            <v-row justify="center" class="mt-10" v-if="total > 1">
                <v-col cols="4">
                    <Pagination
              @pageChanged="onPageChanged"
              :currentPage="currentPage"
              :totalPages="total"
           />
                </v-col>
            </v-row>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px 80px">
                <v-col cols="8">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="3" class="call-center-button" @click="handleToCorporatePage">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Pagination from '@/components/others/Pagination.vue'

export default {
    name: "DesktopPortfolio",
    components: {
        Pagination
    },
    data() {
        return {
            projectPortfolio: [],
            currentPage: 1,
            total: 1,
            perPage: 12,
            currentCategory: "SERVICES",
            categoryList: [
                {
                    key: "SERVICES",
                    value: "Technology Service"
                },
                {
                    key: "COLLABORATION",
                    value: "Corporate Collaboration"
                },
                {
                    value: "Corporate Training",
                    key: "TRAINING",
                    },
                {
                    key: "TALENT_SERVICE",
                    value: "Talent as a Service"
                },
            ],
        }
    },
    methods: {
        handleToCorporatePage() {
            return this.$router.push("/corporate");
        },
        async getPortfolio() {
            try {
                const resp = await this.$http.get(`/v1/project-portfolio?category=${this.currentCategory}&page=${this.currentPage}&per_page=${this.perPage}`)
                this.projectPortfolio = resp.data.data.project_portfolios.filter((item) => item.category === this.currentCategory);
                let lengthData = this.projectPortfolio.length;
                if (lengthData) this.total = resp.data.data.total <= this.perPage ? 1 : (resp.data.data.total / this.perPage) + 1;
            } catch (err) {
                console.log(err);
            }
        },
        onPageChanged(page) {
            this.currentPage = page;
            this.getPortfolio()
        },
        handleChangeCategory(category) {
            this.$router.push(`/portfolio/${category}`);
            window.location.reload()
        }
    },
    mounted() {
        this.currentCategory = this.$route.params.category ?? 'SERVICES';
        this.getPortfolio()
    }
}
</script>

<style scoped src="./portfolio.css"></style>
<template>
	<div style="margin-top: 45px; background-color: #f7f9fd">
		<popup :message="alertErrMessage" v-if="isAlertErr" @close="isAlertErr = false" />
		<div style="
				background: #162ca2;
				margin-bottom: 80px;
				border-bottom-left-radius: 24px;
				border-bottom-right-radius: 24px;
			">
			<img :src="classDetail.image_url" style="
					border-bottom-left-radius: 24px;
					border-bottom-right-radius: 24px;
				" class="img-banner" />
			<div class="text-center" style="padding: 0px 16px 48px 16px">
				<h2 class="sub-title-span-white" style="
						font-weight: 700;
						font-size: 22px;
						line-height: 32px;
					">
					{{ classDetail.name }}
				</h2>

				<p class="sub-title-span-white" style="
						margin-top: 20px;
						font-weight: 400;
						font-size: 13px;
						line-height: 22px;
					">
					Tingkatkan skill menjadi profesional Programmer dengan Job
					Guarantee Program dan dapatkan unlimited career support
					sampai kamu diterima bekerja.
				</p>

				<div style="display: flex; flex-direction: column; gap: 10px">
					<button style="
							background-color: #ffde59;
							height: 40px;
							border-radius: 8px;
							font-size: 14px;
							color: #36373f;
							line-height: 20px;
							font-weight: 700;
						" @click="goToCheckoutPage" v-if="!isRegistrantClose">
						DAFTAR SEKARANG
					</button>
					<button style="
							background-color: #ffffff;
							height: 40px;
							border-radius: 8px;
							font-size: 14px;
							color: #162ca2;
							line-height: 20px;
							font-weight: 700;
						" @click="goToCustomerService">
						KONSULTASI GRATIS
					</button>
				</div>
			</div>
		</div>

		<div style="margin-top: -20px; padding: 0px 16px">
			<div style="margin-bottom: 32px">
				<span style="
						color: #575966;
						font-size: 14px;
						font-weight: 400;
						line-height: 24px;
					">Info Kelas</span>
				<h5 style="
						color: #36373f;
						font-size: 18px;
						font-weight: 700;
						line-height: 28px;
					">
					Deskripsi Program
				</h5>
			</div>

			<div style="
					background-color: #ffff;
					padding: 24px 16px;
					border-radius: 16px;
				">
				<p style="
						color: #575966;
						font-size: 12px;
						font-weight: 400;
						line-height: 20px;
					">
					{{ classDetail.description }}
				</p>

				<h5 style="
						color: #36373f;
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
					">
					Mekanisme Kelas
				</h5>
				<div>
					<div class="d-flex">
						<div>
							<div class="title-mechanism">
								<span>Level</span>
							</div>
							<div class="sub-title-mechanism">
								<span>{{ classDetail.level }}</span>
							</div>
						</div>
					</div>

					<div class="d-flex">
						<div>
							<div class="title-mechanism">
								<span>Metode</span>
							</div>
							<div class="sub-title-mechanism">
								<span>{{ $utils.toMethode(classDetail.method) }}</span>
							</div>
						</div>
					</div>

					<div class="d-flex">
						<div>
							<div class="title-mechanism">
								<span>Sesi Pertemuan</span>
							</div>
							<div class="sub-title-mechanism">
								<span>{{ classDetail.session_total }} sesi</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="padding: 0px 16px">
			<h2 style="
					font-weight: 700;
					font-size: 18px;
					line-height: 28px;
					color: #36373f;
					text-align: center;
					width: 328px;
					margin: 80px auto 32px auto;
				">
				HiColleagues Pilihan Tepat Akselerasi Skill Digital
			</h2>

			<div v-for="(data, idx) in classDetail.why_info_list" :key="idx" class="content-card">
				<div class="left-span">
					<img :src="data.image" width="48" height="48" style="border-radius: 50%;" />
				</div>
				<div>
					<div class="title-2" style="text-align: left">
						{{ data.title }}
					</div>
					<div class="desc-2 mt-2" style="text-align: left">
						{{ data.desc }}
					</div>
				</div>
			</div>
		</div>

		<div style="margin-top: 80px; padding: 0px 16px">
			<span class="title-subs">Tujuan Pembelajaran</span>
			<h5 class="title">Goal pelatihan yang didapatkan</h5>
			<div style="
					background-color: #ffff;
					padding: 16px 24px;
					border-radius: 16px;
					margin-top: 32px;
					margin-bottom: 80px;
				">
				<p id="color-grey" style="font-size: 12px; line-height: 20px" v-html="classDetail.learning_objective">
				</p>
			</div>

			<span class="title-subs">Target Peserta</span>
			<h5 class="title">Kelas ini Cocok untuk</h5>
			<div style="
					background-color: #ffff;
					padding: 16px 24px;
					border-radius: 16px;
					margin-top: 32px;
					margin-bottom: 80px;
				">
				<ul style="color: #575966; font-size: 12px; line-height: 20px">
					<li v-for="(item, index) in classDetail.target_audience" :key="index">
						{{ item.level }}
					</li>
				</ul>
			</div>
		</div>

		<div class="materi-section">
			<span class="title-subs">Materi Silabus</span>
			<h5 class="title">Apa yang kamu pelajari</h5>
			<v-expansion-panels flat>
				<v-expansion-panel style="
						border-radius: 16px;
						margin-bottom: 10px;
						background-color: #f1f6ff;
						margin-top: 24px;
					" v-for="(item, index) in classDetail.syllabuses" :key="index">
					<v-expansion-panel-header disable-icon-rotate @click="openModul(index)" style="
							border-radius: 20px;
							margin: auto;
							min-height: 102px;
						">
						<h4 style="
								line-height: 24px;
								font-size: 16px;
								font-weight: 700;
								color: #36373f;
							">
							{{ item.session }}
						</h4>
						<template v-slot:actions>
							<div v-if="item.active">
								<Arrow />
							</div>
							<div v-else>
								<Arrow :down="!item.active" />
							</div>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content style="border-radius: 20px">
						<div style="
								line-height: 20px;
								font-size: 12px;
								font-weight: 400;
								color: #575966;
							" v-html="item.description"></div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>

		<div style="padding: 0px 16px">
			<div style="margin-bottom: 32px; margin-top: 80px">
				<span class="title-subs">Metode Belajar</span>
				<h5 class="title">
					Ikuti Bootcamp HiColleagues dengan Metode Belajar yang
					Konstruktif
				</h5>
			</div>

			<div v-for="(data, idx) in classDetail.learning_info_list" :key="idx" class="content-card-2">
				<img :src="data.icon" alt="icon-lm" style="border-radius: 50%;" />
				<div>
					<div class="title-2" style="text-align: left">
						{{ data.title }}
					</div>
					<div class="desc-2 mt-2" style="text-align: left">
						{{ data.desc }}
					</div>
				</div>
			</div>
		</div>

		<div style="padding: 0px 16px; margin-top: 80px; margin-bottom: 32px">
			<span class="title-subs">Jadwal Tayang</span>
			<h5 class="title">Jadwal yang Tersedia untuk HiColers Ikuti</h5>
		</div>
		<div class="banner-container">
			<div style="
						background-color: #f1f6ff;
						padding: 32px 16px;
						border-radius: 16px;
					" class="banner-item" v-for="(data, idx) of classDetail.schedules" :key="idx">
				<div>
					<div style="
								font-size: 18px;
								font-weight: 700;
								line-height: 24px;
								color: #36373f;
								padding-bottom: 16px;
								border-bottom: 1px solid #d3d6e1;
								display: flex;
								gap: 18px;
							">
						<span>Batch {{ data.batch_number }}</span>
						<AvailableIcon />
					</div>
				</div>
				<div>
					<div style="
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								color: #7b7e8c;
								margin-top: 24px;
								width: 272px;
							">
						<span>Tanggal Dimulai</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 700;
								line-height: 28px;
								color: #36373f;
							">
						<span>{{ $utils.getDate(data.start_date, 'dddd, DD MMMM YYYY') }}</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 400;
								line-height: 20px;
								color: #36373f;
							">
						<span>{{ $utils.calculateDuration(data.start_date, data.end_date) }}</span>
					</div>
				</div>
				<div>
					<div style="
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								color: #7b7e8c;
								margin-top: 24px;
							">
						<span>Jadwal Kelas</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 700;
								line-height: 28px;
								color: #36373f;
							">
						<span>{{ classDetail.days }}</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 400;
								line-height: 20px;
								color: #36373f;
							">
						<span>{{ classDetail.hours }}</span>
					</div>
				</div>
				<div>
					<div style="
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								color: #7b7e8c;
								margin-top: 24px;
							">
						<span>Kuota Peserta</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 700;
								line-height: 28px;
								color: #36373f;
							">
						<span>{{ data.total_audience }}</span>
					</div>
				</div>
				<div>
					<div style="
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								color: #7b7e8c;
								margin-top: 24px;
							">
						<span>Metode Pelatihan</span>
					</div>
					<div style="
								font-size: 14px;
								font-weight: 700;
								line-height: 28px;
								color: #36373f;
							">
						<span>{{ $utils.toMethode(classDetail.method) }}</span>
					</div>
				</div>
				<div v-if="data.location_address">
					<div style="
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								color: #7b7e8c;
								margin-top: 24px;
							">
						<span>Luring Webinar</span>
					</div>
					<div style="font-size: 14px; font-weight: 700; line-height: 28px; color: #162ca2; text-decoration: underline;">
						<span @click="$utils.redirect(data.location_map)">{{ data.location_address }}</span>
					</div>
				</div>
			</div>
		</div>
		<div style="padding: 0px 16px; margin-top: 80px">
			<span class="title-subs">Biaya Kelas</span>
			<h5 class="title">Pilih Tipe Kelas sesuai Minatmu</h5>
		</div>

		<div class="fee-section">
			<div style="padding: 0px 16px" class="mt-5 banner-container">
				<div class="banner-item">
					<div style="
							padding: 24px;
							background-color: #ffde59;
							border-radius: 16px;
						">
						<div class="bp-type-text mb-2">
							<h2 style="
								font-weight: 700;
								font-size: 18px;
								line-height: 28px;
								margin: 0px;
								
								padding-bottom: 12px;
							">
								Reguler
							</h2>
						</div>
						<div v-if="classDetail.is_discount">
							<button style="
								background-color: #ffff;
								padding: 4px 8px;
								border-radius: 12px;
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								margin-top: 16px;
								margin-bottom: 8px;
							">
								Special Promo
							</button>
							<h4 style="
								font-weight: 400;
								font-size: 16px;
								line-height: 24px;
								text-decoration-line: line-through;
							">
								{{ $utils.formatPrice(classDetail.price) }}
							</h4>
							<h4 style="
								font-weight: 700;
								font-size: 26px;
								line-height: 36px;
								color: #162ca2;
								margin-bottom: 8px;
							">
								{{ $utils.formatPrice(classDetail.price - classDetail.discount_price) }}
							</h4>

						</div>
						<h4 style="
								font-weight: 700;
								font-size: 26px;
								line-height: 36px;
								color: #162ca2;
								margin-bottom: 8px;
							" v-else>
							{{ $utils.formatPrice(classDetail.price) }}
						</h4>

						<div v-for="(n, i) in publicClass" :key="n.message" style="display: flex; margin-top: 17px">
							<ManyPeople v-if="i === 0" />
							<Calendar v-if="i === 1" />
							<UsersOctagon v-if="i === 2" />
							<Bookmark2 v-if="i === 3" />
							<Bill v-if="i === 4" />
							<span class="text-class-type">{{ n.message }}</span>
						</div>
					</div>
				</div>
				<div class="banner-item">
					<div style="
							padding: 24px;
							background-color: #ffde59;
							border-radius: 16px;
						">
						<div class="bp-type-text mb-2">
							<h4>Private</h4>
							<div class="recommended-label">
								<LikeIc :width="20" :height="20" />
								<span>Direkomendasikan</span>
							</div>
						</div>
						<div v-if="classDetail.is_discount">
							<button style="
								background-color: #ffff;
								padding: 4px 8px;
								border-radius: 12px;
								font-size: 12px;
								font-weight: 400;
								line-height: 20px;
								margin-top: 16px;
								margin-bottom: 8px;
							">
								Special Promo
							</button>
							<h4 style="
								font-weight: 400;
								font-size: 16px;
								line-height: 24px;
								text-decoration-line: line-through;
							">
								{{ $utils.formatPrice(classDetail.private_price) }}
							</h4>
							<h4 style="
								font-weight: 700;
								font-size: 26px;
								line-height: 36px;
								color: #162ca2;
								margin-bottom: 8px;
							">
								{{ $utils.formatPrice(classDetail.private_price - classDetail.private_discount_price) }}
							</h4>
						</div>
						<h4 style="
								font-weight: 700;
								font-size: 26px;
								line-height: 36px;
								color: #162ca2;
								margin-bottom: 8px;
							" v-else>
							{{ $utils.formatPrice(classDetail.private_price - classDetail.private_discount_price) }}
						</h4>
						<div v-for="(n, i) in privateClass" :key="n.message" style="display: flex; margin-top: 17px">
							<ManyPeople v-if="i === 0" />
							<Calendar v-if="i === 1" />
							<UsersOctagon v-if="i === 2" />
							<Bookmark2 v-if="i === 3" />
							<Bill v-if="i === 4" />
							<span class="text-class-type">{{ n.message }}</span>
						</div>
					</div>
				</div>
			</div>

			<p style="
					padding: 0px 16px;
					margin: 0px;
					margin-top: 24px;
					text-align: center;
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
				">
				Pendaftaran Ditutup pada <b>{{ $utils.getDate(classDetail.registration_close_date, 'DD MMMM YYYY') }}</b>
			</p>
			<div style="
					display: flex;
					flex-direction: column;
					gap: 10px;
					padding: 0px 16px;
					margin-top: 24px;
				">
				<button style="
						background-color: #162ca2;
						height: 40px;
						border-radius: 8px;
						font-size: 14px;
						color: #36373f;
						line-height: 20px;
						font-weight: 700;
						color: white;
					" @click="goToCheckoutPage" v-if="!isRegistrantClose">
					DAFTAR SEKARANG
				</button>
				<button style="
						background-color: #ffffff;
						height: 40px;
						border-radius: 8px;
						font-size: 14px;
						color: #162ca2;
						line-height: 20px;
						font-weight: 700;
						color: #acafbf;
						border: 1px solid #acafbf;
					" @click="goToCustomerService">
					KONSULTASI GRATIS
				</button>
			</div>
		</div>

		<div class="faqbg">
			<div class="faq">
				<h1>Frequently Asked Question</h1>
				<div class="container">
					<AccordeonFaq v-for="(item, index) in questions" :key="index" :title="item.title"
						:description="item.description" :active="item.active" @toggle="toggleAccordion(index)" />
					<div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')"
						style="background-color: white; margin-top: 30px;">
						<span class="font-poppins">LIHAT SELENGKAPNYA</span>
						<ArrowLong />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AccordeonFaq from "@/components/AccordeonFaq.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import ManyPeople from '@/assets/icons/ManyPeople.vue';
import Calendar from '@/assets/icons/Calendar.vue';
import UsersOctagon from '@/assets/icons/UsersOctagon.vue';
import Bookmark2 from '@/assets/icons/Bookmark2.vue';
import Bill from '@/assets/icons/Bill.vue';
import LikeIc from '@/assets/icons/publictraining/Like.vue';
import AvailableIcon from '@/assets/icons/Available.vue';
import Popup from "@/components/others/Popup.vue";

export default {
	components: {
		ArrowLong,
		Arrow,
		AccordeonFaq,
		ManyPeople,
		Calendar,
		UsersOctagon,
		Bookmark2,
		Bill,
		LikeIc,
		AvailableIcon,
		Popup		
	},
	data() {
		return {
			benefits: [
				{
					title: 'Intensive Program',
					desc: 'Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri',
					image: '/img/bootcamp/ic_intensive_program.png',
				},
				{
					title: 'Competent Trainer',
					desc: 'Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan',
					image: '/img/bootcamp/ic_competent_trainer.png',
				},
				{
					title: 'Learning Management System',
					desc: 'Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat',
					image: '/img/bootcamp/ic_sertifikat_completion.png',
				},
				{
					title: 'E-Certificate',
					desc: 'Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir',
					image: '/img/bootcamp/ic_sertifikat_kompetensi.png',
				},
				{
					title: 'Job Connector',
					desc: 'Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp',
					image: '/img/bootcamp/ic_job_recommentation.png',
				},
				{
					title: 'Installment Option Available',
					desc: 'Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta',
					image: '/img/bootcamp/ic_installment.png',
				},
			],
			publicClass: [
				{ message: 'Lebih dari satu peserta (max 10)' },
				{ message: 'Jadwal kelas telah ditentukan' },
				{ message: 'Diajarkan langsung oleh instruktur' },
				{ message: 'Gratis Survival Kit' },
				{ message: 'Sertifikat' },
			],
			privateClass: [
				{ message: 'Hanya satu peserta' },
				{ message: 'Dapat menentukan jadwal kelas' },
				{ message: 'Diajarkan langsung oleh instruktur' },
				{ message: 'Gratis Survival Kit' },
				{ message: 'Sertifikat' },
			],
			classDetail: {},
			instructor: {},
			questions: [],
			alertErrMessage: "",
			isAlertErr: false,
			isRegistrantClose: false,
		};
	},
	methods: {
		goToCustomerService() {
			window.location.href =
				'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
		},
		async getDetailClass() {
			// this.spin_load.val = true
			await this.$http
				.get('/v1/class-detail/' + this.$route.params.slugname)
				.then((response) => {
					this.classDetail = response.data.data.class_detail;
					this.instructor = response.data.data.instructor;
					this.isRegistrantClose = new Date(this.classDetail.registration_close_date) <= new Date()
				})
				.catch((err) => console.error(err.response));
		},
		goToCheckoutPage() {
			if (this.isRegistrantClose) {
				this.alertErrMessage = "Pendaftaran sudah di tutup";
				this.isAlertErr = true;
				return;
			}
			const req = JSON.stringify({
				class_id: this.classDetail.class_id,
				class_name: this.classDetail.name,
				price: this.classDetail.price,
				discount_price: this.classDetail.discount_price,
				private_price: this.classDetail.private_price,
				private_discount_price: this.classDetail.private_discount_price,
				is_discount: this.classDetail.is_discount,
				level: this.classDetail.level,
				session: this.classDetail.session_total,
				learning_objective: this.classDetail.learning_objective,
				target_audience: this.classDetail.target_audience,
				hour: this.classDetail.hours,
				image_url: this.classDetail.small_image_url,
				class_type: "PUBLIC_CLASS",
				days: this.classDetail.days,
				methode: this.classDetail.method,
				category_id: this.classDetail.category_id,
			});
			localStorage.clear();
			localStorage.setItem('checkout', req);
			window.location.href = `/checkout?category=PUBLIC_TRAINING&slug_name=${this.$route.params.slugname}`;
		},
		toggleAccordion(index) {
			this.questions[index].active = !this.questions[index].active;
		},
		openModul(idx) {
			const valDef = this.classDetail.syllabuses[idx].active;
			this.classDetail.syllabuses.forEach((e) => {
				e.active = false;
			});
			this.classDetail.syllabuses[idx].active = !valDef;
		},
		async getFaq() {
			this.questions = [];
			try {
				const resp = await this.$http.get(
					'/v1/faq?category=DETAIL_CLASS&is_main=true',
				);
				this.questions = resp.data.data.faq.map((item) => ({
					description: item.answer[0],
					title: item.question,
					active: false,
				}));
			} catch (err) {
				console.log(err);
			}
		},
	},
	mounted() {
		this.getDetailClass();
		this.getFaq();
	},
};
</script>

<style src="./css/detailClass.css" scoped></style>

<template>
	<div style="
			margin-top: 60px;
			background-color: #f5f6f9;
			padding-bottom: 60px;
		">
		<popup :message="alertErrMessage" v-if="isAlertErr" @close="isAlertErr = false" />
		<div class="hero" ref="ctn_head">
			<div class="content">
				<div class="d-flex">
					<div class="btn-white-oval">Beginner Friendly</div>
					<div class="btn-white-oval">
						Competent & Certified Instructor
					</div>
					<div class="btn-white-oval">Live Class</div>
				</div>
				<h1>{{ classDetail.name }}</h1>
				<p>
					Tingkatkan skill menjadi profesional dengan Job Guarantee
					Program dan dapatkan unlimited career support sampai kamu
					diterima bekerja.
				</p>
				<div class="btn-cta">
					<button class="now" @click="goToCheckoutPage" v-if="!isRegistrantClose">
						DAFTAR SEKARANG
					</button>
					<button class="call" @click="sendToWa">
						KONSULTASI GRATIS
					</button>
				</div>
			</div>
			<div class="card-img">
				<img :src="classDetail.image_url" width="556" height="392" alt="public-training-hero" />
			</div>
		</div>

		<div class="box-radius">
			<div v-for="(n, idx) in menuPublicClass" :key="idx" class="menu-detail"
				@click="clickActiveMenu(n.layout, n.position)">
				<span style="font-family: 'Poppins'" :class="{ boldnav: layout === n.layout }">{{ n.name }}</span>
			</div>
			<div class="btn-menu">
				<v-btn depressed color="#162CA2" @click="goToCheckoutPage" style="padding: 24px 0; width: 100%"
					class="btn-radius" v-if="!isRegistrantClose">
					<span class="font-poppins font-weight-bold white--text">DAFTAR SEKARANG</span>
				</v-btn>
				<v-btn depressed color="#FFFFFF" @click="sendToWa" style="
						padding: 24px 0;
						width: 100%;
						border: 1px solid #acafbf;
					" class="btn-radius">
					<span class="font-poppins font-weight-bold text-gray">KONSULTASI GRATIS</span>
				</v-btn>
			</div>
		</div>

		<div class="container-parent" style="margin-top: -460px" ref="info_kelas">
			<p class="subtitle">Info Kelas</p>
			<h5 class="title">Deskripsi Program</h5>
			<div class="container-detail">
				<p class="desc">{{ classDetail.description }}</p>
				<h5 class="container-detail-subtitle">Mekanisme Kelas</h5>
				<div class="flex-info">
					<div class="class-mechanism-box">
						<span class="title-mechanism">Level Pelatihan</span>
						<span class="sub-title-mechanism">{{
							classDetail.level
						}}</span>
					</div>
					<div class="class-mechanism-box">
						<span class="title-mechanism">Metode Pelatihan</span>
						<span class="sub-title-mechanism">{{
							$utils.toMethode(classDetail.method)
						}}</span>
					</div>
					<div class="class-mechanism-box">
						<span class="title-mechanism">Total Durasi Sesi</span>
						<span class="sub-title-mechanism">{{
							classDetail.session_total
						}}</span>
					</div>
				</div>
			</div>
		</div>

		<div ref="mengapa_hicolleagues">
			<div class="container-parent" style="margin-top: 104px">
				<p class="subtitle">Mengapa HiColleagues</p>
				<h5 class="title">
					Wujudkan Karir Impian dengan Intensive Program HiColleagues
				</h5>
				<div class="card-benefits">
					<div v-for="(data, idx) in classDetail.why_info_list" :key="idx" class="content-card">
						<div class="left-span">
							<img :src="data.image" width="56" height="56" style="border-radius: 50%" />
						</div>
						<div>
							<div class="title-2" style="text-align: left">
								{{ data.title }}
							</div>
							<div class="desc-2 mt-2" style="text-align: left">
								{{ data.desc }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-parent" style="margin-top: 104px">
				<p class="subtitle">Tujuan Pembelajaran</p>
				<h5 class="title">
					Tujuan Pembelajaran yang Peserta Akan Dapatkan
				</h5>
				<div v-html="classDetail.learning_objective" class="container-detail-tujuan"></div>
			</div>
		</div>

		<div class="container-parent" ref="target_peserta" style="margin-top: 104px; padding-bottom: 90px">
			<p class="subtitle">Target Peserta</p>
			<h5 class="title">Kriteria Peserta Program Akselerasi Digital</h5>
			<div class="container-detail-target">
				<ul>
					<li v-for="(item, index) in classDetail.target_audience" :key="index">
						{{ item.level }}
					</li>
				</ul>
			</div>
		</div>

		<div class="bg-container" style="padding-bottom: 50px; " ref="materi_silabus">
			<div class="container-detail" style="margin: 45px 0; padding: 40px 0px; padding-right: 130px">
				<span>Materi Silabus</span>
				<h5 class="title">Apa Saja yang Akan HiColers Pelajari</h5>
				<v-expansion-panels flat class="session-card-container" v-model="panels" multiple>
					<v-expansion-panel v-for="(item, idx) in classDetail.syllabuses" :key="item.session" style="
							border-radius: 20px;
							padding: 0;
							margin-bottom: 10px;
							background-color: #f1f6ff;
						">
						<v-expansion-panel-header disable-icon-rotate @click="openSession(idx)">
							<h2 style="
									margin: 25px 0;
									font-size: 16px;
									font-weight: 700;
									line-height: 22px;
									color: #36373f;
								">
								{{ item.session }}
							</h2>
							<template v-slot:actions>
								<div class="icon">
									<Arrow :down="!item.active" :width="28" :height="28" />
								</div>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="item.description" style="
									width: 95%;
									font-weight: 400;
									font-size: 14px;
									line-height: 28px;
									font-family: 'Poppins', sans-serif;
								"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</div>

		<div class="container-parent" ref="metode_belajar" style="padding-top: 104px">
			<p class="subtitle">Metode Belajar</p>
			<h5 class="title">
				Ikuti Bootcamp HiColleagues dengan Metode Belajar yang
				Konstruktif
			</h5>
			<div class="container-detail-learning-method">
				<div v-for="(item, index) in classDetail.learning_info_list" :key="index" class="card-lm">
					<img :src="item.icon" alt="icon-lm" />
					<h4>{{ item.title }}</h4>
					<p>{{ item.desc }}</p>
				</div>
			</div>
		</div>

		<div ref="instruktur"></div>

		<div class="container-parent" ref="jadwal_tayang" style="margin-top: 104px">
			<p class="subtitle">Jadwal Tayang</p>
			<h5 class="title">Jadwal yang Tersedia untuk HiColers Ikuti</h5>
			<div class="container-detail-jadwal-tayang">
				<div v-for="(item, idx) in classDetail.schedules" :key="idx" class="detail-jadwal-tayang-box">
					<div class="batch">
						<h3>Batch {{ item.batch_number }}</h3>
						<AvailableIcon />
					</div>
					<hr style="margin-top: -10px" color="#D3D6E1" />
					<div class="detail-info">
						<div class="detail-info-item">
							<span>Tanggal Dimulai</span>
							<h5>{{ $utils.getDate(item.start_date, 'dddd, DD MMMM YYYY') }}</h5>
							<p>(Durasi {{ $utils.calculateDuration(item.start_date, item.end_date) }})</p>
						</div>
						<div class="detail-info-item" style="min-width: 140px;">
							<span>Jadwal Kelas</span>
							<h5>{{ classDetail.days }}</h5>
							<p>({{ classDetail.hours }})</p>
						</div>
						<div class="detail-info-item" style="min-width: 110px;">
							<span>Kuota Peserta</span>
							<h5>{{ item.total_audience }}</h5>
						</div>
						<div class="detail-info-item">
							<span>Metode Pelatihan</span>
							<h5>{{ $utils.toMethode(classDetail.method) }}</h5>
						</div>
						<div class="detail-info-item" v-if="item.location_address">
							<span>Luring Webinar</span>
							<h5 class="cursor-pointer" @click="$utils.redirect(item.location_map)">
								{{ item.location_address }}
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-parent" ref="biaya_pelatihan" style="margin-top: 104px">
			<p class="subtitle">Biaya Pelatihan</p>
			<h5 class="title">Investasi Masa Depan dengan Harga Terjangkau</h5>
			<div class="container-detail-biaya-pelatihan">
				<div class="bp-type">
					<div class="bp-type-text">
						<h4>Reguler</h4>
					</div>
					<hr color="#7B7E8C" />
					<div class="bp-detail">
						<div class="price-promo">
							<div v-if="classDetail.is_discount">
								<span>Special Promo</span>
								<p class="normal-price">
									{{ $utils.formatPrice(classDetail.price) }}
								</p>
								<h4>{{ $utils.formatPrice(classDetail.price - classDetail.discount_price) }}</h4>
							</div>
							<h4 v-else>{{ $utils.formatPrice(classDetail.price) }}</h4>
						</div>
						<div class="bp-benefit">
							<div v-for="(
									itemBenefit, indexBenefit
								) in publicClass" :key="indexBenefit" class="bp-benefit-item">
								<PeopleIc :width="20" :height="20" v-if="indexBenefit === 0" />
								<CalendarIc :width="20" :height="20" v-if="indexBenefit === 1" />
								<UserOctagonIc :width="20" :height="20" v-if="indexBenefit === 2" />
								<BookmarkIc :width="20" :height="20" v-if="indexBenefit === 3" />
								<BillIc :width="20" :height="20" v-if="indexBenefit === 4" />
								<p>{{ itemBenefit.message }}</p>
							</div>
						</div>
					</div>
					<div class="sk"><span>*S&K Berlaku</span></div>
				</div>
				<div class="bp-type">
					<div class="bp-type-text">
						<h4>Private</h4>
						<div class="recommended-label">
							<LikeIc :width="20" :height="20" />
							<span>Direkomendasikan</span>
						</div>
					</div>
					<hr color="#7B7E8C" />
					<div class="bp-detail">
						<div class="price-promo">
							<div v-if="classDetail.is_discount">
								<span>Special Promo</span>
								<p class="normal-price">
									{{ $utils.formatPrice(classDetail.private_price) }}
								</p>
								<h4>{{ $utils.formatPrice(classDetail.private_price - classDetail.private_discount_price
								) }}
								</h4>
							</div>
							<h4 v-else>{{ $utils.formatPrice(classDetail.private_price) }}</h4>
						</div>
						<div class="bp-benefit">
							<div v-for="(
									itemBenefit, indexBenefit
								) in privateClass" :key="indexBenefit" class="bp-benefit-item">
								<FrameIc :width="20" :height="20" v-if="indexBenefit === 0" />
								<CalendarEditIc :width="20" :height="20" v-if="indexBenefit === 1" />
								<UserOctagonIc :width="20" :height="20" v-if="indexBenefit === 2" />
								<BookmarkIc :width="20" :height="20" v-if="indexBenefit === 3" />
								<BillIc :width="20" :height="20" v-if="indexBenefit === 4" />
								<p>{{ itemBenefit.message }}</p>
							</div>
						</div>
					</div>
					<div class="sk"><span>*S&K Berlaku</span></div>
				</div>
				<div class="cta-class">
					<p>Pendaftaran Ditutup pada <b>{{ $utils.getDate(classDetail.registration_close_date, 'DD MMMM YYYY')
						}}</b></p>
					<div class="cta-class-btn">
						<button class="cta-call" @click="sendToWa">
							KONSULTASI GRATIS
						</button>
						<button class="cta-now" @click="goToCheckoutPage" v-if="!isRegistrantClose">
							DAFTAR SEKARANG
						</button>
					</div>
				</div>
			</div>
		</div>

		<div ref="metode_pembayaran"></div>

		<div class="container-parent" ref="faq" style="margin-top: 104px">
			<p class="subtitle">FAQ</p>
			<h5 class="title">Pertanyaan Seputar Program</h5>
			<div class="container-faq">
				<v-expansion-panels flat class="faq-card">
					<v-expansion-panel v-for="(item, index) in questions" :key="index" style="
							border-radius: 20px;
							padding: 0;
							margin-bottom: 10px;
							background-color: #ffffff;
						">
						<v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
							<h2 style="
									margin: 25px 0;
									font-size: 16px;
									font-weight: 700;
									line-height: 22px;
									color: #36373f;
								">
								{{ item.title }}
							</h2>
							<template v-slot:actions>
								<div class="icon">
									<Arrow :width="28" :height="28" :down="!item.active" />
								</div>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="item.description" style="
									width: 95%;
									font-weight: 400;
									font-size: 14px;
									line-height: 28px;
									font-family: 'Poppins', sans-serif;
								"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<div class="btn-stoke-gray cursor-pointer" @click="$router.push('/faq')">
					<span class="font-poppins">LIHAT SELENGKAPNYA</span>
					<ArrowLong fill="#575966" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto';
import Arrow from '@/assets/icons/Arrow2.vue';
import BillIc from '@/assets/icons/publictraining/Bill.vue';
import BookmarkIc from '@/assets/icons/publictraining/Bookmark.vue';
import CalendarIc from '@/assets/icons/publictraining/Calendar.vue';
import CalendarEditIc from '@/assets/icons/publictraining/CalendarEdit.vue';
import FrameIc from '@/assets/icons/publictraining/Frame.vue';
import LikeIc from '@/assets/icons/publictraining/Like.vue';
import PeopleIc from '@/assets/icons/publictraining/People.vue';
import UserOctagonIc from '@/assets/icons/publictraining/UserOctagon.vue';
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Popup from "@/components/others/Popup.vue";
import AvailableIcon from '@/assets/icons/Available.vue';

export default {
	components: {
		Popup,
		Arrow,
		ArrowLong,
		BillIc,
		BookmarkIc,
		CalendarIc,
		CalendarEditIc,
		FrameIc,
		LikeIc,
		PeopleIc,
		UserOctagonIc,
		AvailableIcon
	},
	data() {
		return {
			benefits: [],
			isRegistrantClose: false,
			learningMethods: [],
			publicClass: [
				{ message: 'Lebih dari satu peserta, mak. 10 peserta' },
				{ message: 'Jadwal kelas telah ditentukan' },
				{ message: 'Diajarkan langsung oleh instruktur' },
				{ message: 'Gratis Survival Kit' },
				{ message: 'Sertifikat' },
			],
			privateClass: [
				{ message: 'Intensif hanya satu peserta' },
				{ message: 'Dapat menentukan jadwal kelas' },
				{ message: 'Diajarkan langsung oleh instruktur' },
				{ message: 'Gratis Survival Kit' },
				{ message: 'Sertifikat' },
			],
			questions: [],
			faq: [
				{
					description:
						'Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.',
					title: 'Apa yang harus dipersiapkan untuk mengikuti pelatihan?',
				},
				{
					description:
						'Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.',
					title: 'Apa yang harus dipersiapkan untuk mengikuti pelatihan?',
				},
				{
					description:
						'Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.',
					title: 'Apa yang harus dipersiapkan untuk mengikuti pelatihan?',
				},
			],
			menuPublicClass: [
				{
					name: 'Info Kelas',
					position: 0,
					layout: 'info_kelas',
				},
				{
					name: 'Mengapa HiColleagues',
					position: 0,
					layout: 'mengapa_hicolleagues',
				},
				{
					name: 'Target Peserta',
					position: 0,
					layout: 'target_peserta',
				},
				{
					name: 'Materi Silabus',
					position: 0,
					layout: 'materi_silabus',
				},
				{
					name: 'Metode Belajar',
					position: 0,
					layout: 'metode_belajar',
				},
				{
					name: 'Jadwal Tayang',
					position: 0,
					layout: 'jadwal_tayang',
				},
				{
					name: 'Biaya Pelatihan',
					position: 0,
					layout: 'biaya_pelatihan',
				},
				{
					name: 'FAQ',
					position: 0,
					layout: 'faq',
				},
			],
			classDetail: {},
			alertErrMessage: "",
			isAlertErr: false,
			layout: 'info_kelas',
			panels: [],
		};
	},
	methods: {
		clickActiveMenu(e, p) {
			this.layout = e;
			goTo(Number(p + 10));
		},
		goToCheckoutPage() {
			if (this.isRegistrantClose) {
				this.alertErrMessage = "Pendaftaran sudah di tutup";
				this.isAlertErr = true;
				return;
			}
			const req = JSON.stringify({
				class_id: this.classDetail.class_id,
				class_name: this.classDetail.name,
				price: this.classDetail.price,
				discount_price: this.classDetail.discount_price,
				private_price: this.classDetail.private_price,
				private_discount_price: this.classDetail.private_discount_price,
				is_discount: this.classDetail.is_discount,
				level: this.classDetail.level,
				session: this.classDetail.session_total,
				learning_objective: this.classDetail.learning_objective,
				target_audience: this.classDetail.target_audience,
				hour: this.classDetail.hours,
				image_url: this.classDetail.small_image_url,
				class_type: "PUBLIC_CLASS",
				days: this.classDetail.days,
				methode: this.classDetail.method,
				category_id: this.classDetail.category_id,
			});

			localStorage.clear();
			localStorage.setItem('checkout', req);
			window.location.href = `/checkout?category=PUBLIC_TRAINING&slug_name=${this.$route.params.slugname}`;
		},
		goToCustomerService() {
			window.location.href =
				'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
		},
		mappingHeightOfElement() {
			let point = this.$refs.ctn_head.clientHeight;
			this.menuPublicClass[0].position = point;
			point += this.$refs.info_kelas.clientHeight;
			this.menuPublicClass[1].position = point;
			point += this.$refs.mengapa_hicolleagues.clientHeight;
			this.menuPublicClass[2].position = point;
			point += this.$refs.target_peserta.clientHeight;
			point += 300
			this.menuPublicClass[3].position = point;
			point += this.$refs.materi_silabus.clientHeight;
			this.menuPublicClass[4].position = point;
			point += this.$refs.metode_belajar.clientHeight;
			this.menuPublicClass[5].position = point;
			point += this.$refs.jadwal_tayang.clientHeight;
			this.menuPublicClass[6].position = point;
			point += this.$refs.biaya_pelatihan.clientHeight;
			this.menuPublicClass[7].position = point;
		},
		async getDetailClass() {
			// this.spin_load.val = true
			await this.$http
				.get('/v1/class-detail/' + this.$route.params.slugname)
				.then((response) => {
					this.classDetail = response.data.data.class_detail;
					this.classDetail = {
						...this.classDetail,
						syllabuses: this.classDetail.syllabuses.map((s) => ({
							...s,
							active: true,
						})),
					};
					let syllabusesLength = this.classDetail.syllabuses.length;
					this.panels = [...Array(syllabusesLength).keys()];
					if (this.classDetail.category_id === 5) {
						this.$router.push('/class/bootcamp/' + this.$route.params.slugname);
						return
					}
					this.isRegistrantClose = new Date(this.classDetail.registration_close_date) <= new Date()
					setTimeout(() => {
						this.mappingHeightOfElement();
					}, 1000);
				})
				.catch(() => {
					this.$router.push('/class/public-training');
				});
		},
		async getFaq() {
			this.questions = []
			try {
				const resp = await this.$http.get("/v1/faq?category=DETAIL_CLASS&is_main=true")
				this.questions = resp.data.data.faq.map((item) => ({
					description: item.answer[0],
					title: item.question,
					active: false
				}))
			} catch (err) {
				console.log(err);

			}
		},
		handleScroll() {
			this.menuPublicClass.forEach((item) => {
				if (item.position <= window.scrollY) {
					this.layout = item.layout;
				}
			});
		},
		sendToWa() {
			window.open(
				'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya',
			);
		},
		openSession(idx) {
			const valDef = this.classDetail.syllabuses[idx].active;
			this.classDetail.syllabuses[idx].active = !valDef;
		},
		openFaq(idx) {
			const valDef = this.questions[idx].active;
			this.questions.forEach((e) => {
				e.active = false;
			});
			this.questions[idx].active = !valDef;
		},
	},
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo);
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	mounted() {
		this.getDetailClass();
		this.getFaq()
	},
};
</script>

<style src="./css/detailClass.css" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.outline)?_c('button',{class:!_vm.disabled ? 'button' : 'button-disabled',style:(`max-width: ${_vm.width}; height: ${_vm.height}; width: 100%; font-weight: ${
    _vm.bold ? 'bold' : 'bold'
  };`),attrs:{"title":_vm.title},on:{"click":function($event){$event.stopPropagation();return _vm.onSubmit.apply(null, arguments)}}},[(!_vm.loading)?_c('div',{staticClass:"font-poppins",style:({
      fontWeight: _vm.bold ? 'bold' : '',
    })},[(_vm.icon)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('button',{class:!_vm.disabled
      ? _vm.outlinecolor
        ? 'button-outline-2'
        : 'button-outline'
      : _vm.outlinecolor
      ? 'button-disabled-outline'
      : 'button-disabled-outline',style:(`max-width: ${_vm.width}; height: ${_vm.height}; width: 100%; font-weight: ${
    _vm.bold ? 'bold' : ''
  }`),attrs:{"title":_vm.title},on:{"click":function($event){$event.stopPropagation();return _vm.onSubmit.apply(null, arguments)}}},[(!_vm.loading)?_c('div',{staticClass:"font-poppins"},[(_vm.icon)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
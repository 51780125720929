import { render, staticRenderFns } from "./SoftSkillCategory.vue?vue&type=template&id=56f0370a&scoped=true"
import script from "./SoftSkillCategory.vue?vue&type=script&lang=js"
export * from "./SoftSkillCategory.vue?vue&type=script&lang=js"
import style0 from "./css/technologyCategory.css?vue&type=style&index=0&id=56f0370a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f0370a",
  null
  
)

export default component.exports
<template>
  <div class="mt-10" style="background-color: #F5F6F9;">
    <popup
      :message="notif.alertErrMessage"
      v-if="notif.isAlertErr"
      @close="notif.isAlertErr = false"
    />
    <div class="hero">
			<div class="content">
				<h1>
					Temukan Event Menarik & Buat <br> Digital Upskilling-mu Makin Seru!
				</h1>
				<p>
					<b>#MulaiLangkahHebat</b> dengan gabung event menarik yang HiColers minati dan siap melesat <br> bersama HiColleagues!
				</p>
				<div class="btn-cta">
					<button
						class="now"
						@click="scrollToSection('event-recommendation')">
						LIHAT EVENT
					</button>
				</div>
			</div>
			<div class="card-img">
				<img
					src="/img/event/banner.png"
					width="556"
					height="392"
					alt="event-banner" />
			</div>
		</div>
    <div class="container" style="text-align: center;">
      <div class="section-container">
          <h1 class="sub-title-span" style="margin: 0;">Kenapa Harus Ikut Event HiColleagues?</h1>
          <span class="font-poppins">Berbagai benefit yang akan HiColers dapatkan dalam Event
          HiColleagues</span>
        <div class="card-bootcamp">
          <div v-for="n in benefits" :key="n.id" class="content-card">
            <div class="left-span">
              <img :src="n.image" width="104" height="104" />
            </div>
            <div class="title-2">{{ n.title }}</div>
            <div class="desc mt-2" style="font-family: 'Poppins', sans-serif;">{{ n.desc }}</div>
          </div>
        </div>
      </div>
      <div class="section-container" id="event-recommendation">
        <h1 class="sub-title-span" style="margin: 0;">Rekomendasi Event HiColleagues</h1>
        <span class="font-poppins">Event menarik yang tersedia untuk HiColers</span>
        <div class="event-list" v-if="events.length > 0">
          <div class="event-box" v-for="(item, idx) in events" :key="idx" :title="item.name" @click="toDetail(item)">
            <img
              :src="item.img_urls.desktop.thumbnail"
              alt="Content"
              class="event-box-img"
            />
            <div class="event-ctn">
              <div style="text-align: left;">
                <div class="event-ctn-label">
                  <span>{{ item.category.name }}</span>
                </div>
                <div style="height: 65px; margin-top: 10px;">
                  <h3 class="judul-event">{{ item.name }} {{ item.batch ? "Batch " + item.batch : '' }}</h3>
                </div>
                <div class="event-ctn-info" style="margin-top: 0;">
                  <DateIcon :width="18" :height="18" />
                  <span class="name">{{ moment(item.session_schedules[0].date_start) }}</span>
                </div>
                <div class="event-ctn-info">
                  <Clock :width="18" :height="18" />
                  <span class="name"
                    >{{ item.session_schedules[0].start_time }} - {{ item.session_schedules[0].end_time }} WIB</span
                  >
                </div>
                <div class="event-ctn-info">
                  <Participants :width="18" :height="18" />
                  <span class="name"
                    >{{ item.participant_quota > 0 ? `${item.participant_quota} peserta` : 'Tidak terbatas' }}</span
                  >
                </div>
                <div class="event-ctn-info">
                  <Methode :width="18" :height="18" />
                  <span class="name">{{ item.method | toMethode }}</span>
                </div>
                <div class="event-ctn-info" v-if="item.instructors.length">
                  <Speaker :width="18" :height="18" fill="#7B7E8C" />
                  <span class="name"
                    >{{ item.instructors[0].name
                    }}<span v-if="item.instructors.length > 1"
                      >, {{ item.instructors.length - 1 }}+ lainnya</span
                    ></span>
                </div>
                <div style="height: 35px" v-else></div>
                <div style="height: 50px; margin-top: 24px;">
                  <div v-if="checkIsEarlyBird(item)">
                    <span class="ebp-text">{{ item.label_discount }}</span>
                    <div class="after-price-ctn">
                      <span class="discount-price">{{ item.price != 0 ? toCurrency(item.price) : '' }}</span>
                      <span class="event-price">{{ (item.price - item.discount_price) ? toCurrency(item.price - item.discount_price) : "GRATIS" }}</span>
                    </div>
                  </div>
                  <div class="event-price" v-else>
                    {{ item.price ? toCurrency(item.price) : "GRATIS" }}
                  </div>
                </div>
                <div v-if="item.expired.length"
                  style="display: flex; justify-content: center; margin-top: 16px;"
                >
                  <Button
                    name="DAFTAR"
                    :bold="true"
                    width="250px"
                    @click="() => toDetail(item)"
                  />
                </div>
                <div v-else
                  style="display: flex; justify-content: center; margin-top: 16px;"
                >
                  <button class="btn-disable"
                    disabled >
                    <CloseSlash :width="20" :height="20" />
                    <span>Telah Usai</span>
                  </button>
                </div>
                <div class="event-due-date" v-if="item.expired.length">
                  <div class="event-due-date-ctn">
                    <span class="event-due-date-key">{{ item.expired[0] }}</span>
                    <span class="event-due-date-value">Hari</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span class="event-due-date-key">{{ item.expired[1] }}</span>
                    <span class="event-due-date-value">Jam</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span class="event-due-date-key">{{ item.expired[2] }}</span>
                    <span class="event-due-date-value">Menit</span>
                  </div>
                  <span>:</span>
                  <div class="event-due-date-ctn">
                    <span class="event-due-date-key">{{ item.expired[3] }}</span>
                    <span class="event-due-date-value">Detik</span>
                  </div>
                </div>
                <div v-else style="height: 48px;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-data" v-else>
          <img src="/img/empty_folder.png" alt="">
          <div class="empty-data-text">
            <h3>Ups, Belum Ada Event Nih!</h3>
            <p>Halo HiColers! Saat ini belum ada event yang tersedia. Tunggu informasi selanjutnya. Terima kasih!</p>
          </div>
        </div>
        <v-row justify="center" style="margin-top: 30px;" v-if="total > 1">
          <v-col>
            <Pagination
              @pageChanged="onPageChanged"
              :currentPage="currentPage"
              :totalPages="total"
           />
          </v-col>
        </v-row>
      </div>
      <div class="bg">
        <div class="box-content">
          <div>
            <span class="title font-poppins"
              >Masih Bingung untuk Gabung Event HiColleagues?</span
            >
            <div>
              <span class="description font-poppins"
                >Sesuaikan minatmu dengan konsultasi bersama HiColleagues</span
              >
            </div>
          </div>
          <div>
            <div class="d-flex bg-yellow justify-center" @click="contactMe">
              <span class="exhange-voucher font-poppins">KONSULTASI GRATIS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Button from "../../others/Button.vue";
import Clock from "@/assets/icons/Clock2.vue";
import DateIcon from "@/assets/icons/Calendar2.vue";
import Speaker from "@/assets/icons/UsersOctagon.vue";
import Methode from "@/assets/icons/Presentation.vue";
import Popup from "@/components/others/Popup.vue";
import Participants from '@/assets/icons/Participants.vue';
import CloseSlash from "@/assets/icons/CloseSlash.vue";
import Pagination from '@/components/others/Pagination.vue'

export default {
  name: "EventDesktop",
  data() {
    return {
      currentPage: 1,
      total: 0,
      perPage: 12,
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Narasumber Profesional",
          desc: "Event semakin menarik karena diisi oleh narasumber profesional & pengalaman di bidangnya.",
        },
        {
          image: "/img/event/topik-ter-update.png",
          title: "Topik Ter-update",
          desc: "Topik hangat & menarik untuk melengkapi wawasan, informasi, & keterampilan HiColers.",
        },
        {
          image: "/img/event/interaktif-sesi.png",
          title: "Interaktif Sesi",
          desc: "Komunikasi 2 arah antara narasumber & peserta untuk menciptakan proses pembelajaran yang interaktif.",
        },
        {
          image: "/img/event/e-certificate.png",
          title: "E-Certificate",
          desc: "Peserta mendapatkan sertifikat Partisipan atau Penyelesain setelah event berlangsung.",
        },
        {
          image: "/img/event/harga_terjangkau.png",
          title: "Harga Terjangkau",
          desc: "Tidak perlu risau karena dapat diakses dengan harga terjangkau & banyak promo seru lainnya.",
        },
      ],
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      events: [],
      isAlertErr: false,
    };
  },
  components: {
    Button,
    DateIcon,
    Popup,
    Clock,
    Methode,
    Speaker,
    Participants,
    CloseSlash,
    Pagination
  },
  methods: {
    contactMe() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants  
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.slug_name)
      if (total_registrants >= item.participant_quota && item.participant_quota !== 0) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh"
        return
      }
      this.$router.push("/checkout/event/" + item.slug_name);
    },
    toDetail(item) {
      this.$router.push("/event/detail/" + item.slug_name);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.events.forEach((item) => {
          const end = new Date(item.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item.expired = [days, hours, minutes, seconds];
        });
      };
      setInterval(showRemaining, 1000);
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get(`/v1/event?is_active=true&page=${this.currentPage}&per_page=${this.perPage}`);
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules ? item.session_schedules  
            ? item.session_schedules
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ] : [{
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                }],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        }));
        this.total = parseInt(resp.data.data.total / this.perPage) + 1;
      } catch (err) {
        console.log(err);
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getDataEvents()
    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    scrollToSection(sectionId) {
			const element = document.getElementById(sectionId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		},
  },
  mounted() {
    this.getDataEvents();
    this.countDownTimer();
    this.getFaq()
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./event.css" scoped></style>
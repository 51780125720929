<template>
  <div>
      <DesktopPublicTraining v-if="!isMobile"/>
      <MobilePublicTraining v-if="isMobile"/>
  </div>
</template>

<script>
import DesktopPublicTraining from '@/components/public-training/desktop/PublicTraining'
import MobilePublicTraining from '@/components/public-training/mobile/PublicTraining'
export default {
  components: {
    DesktopPublicTraining,
    MobilePublicTraining
  },
  metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.$route.meta.title,
      htmlAttrs: { lang: "id", amp: true},
      meta: [
        { name: "keywords", content: "Public Training, Training, Outsourcing" },
        { name: "description", content: this.$route.meta.description},
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.$route.meta.title,
            "url": thisPath
          }
        }
      ]
    };
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990
      }
  },
  mounted() {
    this.$gtag.pageview(this.$route)
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView()
    sessionStorage.setItem('nav-item-active', 0)
  },
}

</script>
import { render, staticRenderFns } from "./RegistrasionForm.vue?vue&type=template&id=4f4bbe01&scoped=true"
import script from "./RegistrasionForm.vue?vue&type=script&lang=js"
export * from "./RegistrasionForm.vue?vue&type=script&lang=js"
import style0 from "./RegistrasionForm.vue?vue&type=style&index=0&id=4f4bbe01&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4bbe01",
  null
  
)

export default component.exports
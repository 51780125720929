var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"50px"}},[_vm._m(0),_c('div',{staticStyle:{"background-color":"#F5F6F9"}},[_c('v-row',{staticClass:"container",staticStyle:{"margin":"0 !important"}},[_c('v-col',[_c('div',{staticStyle:{"gap":"10px","display":"flex","overflow-x":"auto"}},_vm._l((_vm.program),function(item,idx){return _c('v-chip',{key:idx,class:[
          _vm.selectedProgram == idx ? 'sub-program-active' : '',
          'sub-program',
        ],on:{"click":function($event){return _vm.changeProgram(idx, item.key)}}},[_vm._v(_vm._s(item.name))])}),1),(_vm.program[_vm.selectedProgram].sub_program)?_c('h4',{staticClass:"text-center my-5"},[_vm._v("Sub menu")]):_vm._e(),(_vm.program[_vm.selectedProgram].sub_program)?_c('div',{staticStyle:{"gap":"10px","display":"flex","overflow-x":"auto"}},_vm._l((_vm.program[_vm.selectedProgram].data),function(item,idx){return _c('v-chip',{key:idx,class:[
          _vm.selectedSubProgram == idx ? 'sub-program-active' : '',
          'sub-program',
        ],on:{"click":function($event){return _vm.changeSubProgram(idx, item.key)}}},[_vm._v(_vm._s(item.name))])}),1):_vm._e(),(!_vm.program[_vm.selectedProgram].sub_program)?_c('div',{staticStyle:{"margin-top":"30px"}},_vm._l((_vm.dataFaq),function(item,index){return _c('AccordeonFaq',{key:index,attrs:{"title":item.title,"description":item.description,"active":item.active},on:{"toggle":function($event){return _vm.toggleAccordion(index)}}})}),1):_vm._e(),(_vm.program[_vm.selectedProgram].sub_program)?_c('div',{staticStyle:{"margin-top":"30px"}},_vm._l((_vm.dataFaq),function(item,index){return _c('AccordeonFaq',{key:index,attrs:{"title":item.title,"description":item.description,"active":item.active},on:{"toggle":function($event){return _vm.toggleAccordion(index)}}})}),1):_vm._e(),(!_vm.dataFaq.length)?_c('empty-data',{attrs:{"message":"Belum ada faq saat ini"}}):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"call-center-container",attrs:{"elevation":"0"}},[_c('v-row',{staticStyle:{"padding":"20px 8px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"call-center-title"},[_vm._v(" Belum Menemukan Jawaban Seputar Program HiColleagues? ")]),_c('div',{staticClass:"call-center-desc"},[_vm._v(" Dapatkan berbagai informasi yang kamu butuhkan melalui layanan 24/7 Customer Service HiColleagues ")])]),_c('v-col',{staticClass:"call-center-button",attrs:{"cols":"12"},on:{"click":_vm.sendToWa}},[_c('v-btn',{staticClass:"btn",attrs:{"elevation":"0"}},[_vm._v(" HUBUNGI KAMI ")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq-hero"},[_c('div',{staticClass:"faq-hero-sec"},[_c('div',{staticStyle:{"font-weight":"700","font-size":"30px"}},[_vm._v(" FREQUENTLY ASKED QUESTION ")]),_c('div',{staticStyle:{"font-weight":"400","font-size":"13px"}},[_vm._v(" Temukan beragam informasi yang kamu butuhkan seputar program HiColleagues di sini! ")])])])
}]

export { render, staticRenderFns }
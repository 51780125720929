<template>
  <div style="overflow-x: none !important">
    <div class="sub-banner">
      <div class="nav-menu">
        <component :is="sosmed.img" width="25" height="25" alt="" @click="toSosmed(sosmed.link)"
          v-for="(sosmed, index) in soscialMedia" :key="index" style="cursor: pointer" />
      </div>
      <h1 class="title-banner">Solusi Inovatif Karir & Bisnis Menuju <br>
        Transformasi Digital Berkelanjutan</h1>
      <p class="sub-title-banner" style="max-width: 1200px; width: 100%; margin: auto;  font-weight: 300">Langkah Tepat
        Kolaborasi Profesional melalui program Akselerasi Digital Upskilling, New Skilling, & Reskilling
        Bersama <b style="font-weight: bold; font-family: 'Poppins';">#CiptakanMasaDepan</b></p>
      <div class="menu-flex">
        <div class="ctn" @click="goToBootcamp">
          <BootcampIc />
          <span style="margin-top: 10px">Bootcamp</span>
        </div>
        <div class="ctn" @click="goToClass">
          <PublicIc />
          <span style="margin-top: 10px">Public Training</span>
        </div>
        <div class="ctn" @click="goToCorporateTraining">
          <CorpIc />
          <span style="margin-top: 10px">Corporate Service</span>
        </div>
        <div class="ctn" @click="goToPrakerja">
          <PraIc />
          <span style="margin-top: 10px">Kartu Prakerja</span>
        </div>
        <div class="ctn" @click="goToEvent">
          <EventIc />
          <span style="margin-top: 10px">Event</span>
        </div>
      </div>
    </div>
    <div class="container-fade">
      <div class="left-fade"></div>
      <div class="right-fade"></div>
    </div>
    <div class="logo">
      <div class="card-container">
      <div class="card">
        <div v-for="(partner, index) in partners" :key="index">
          <div class="logo-card-partner-2">
            <!-- <img src="/img/partner/astrapay.png" width="160" /> -->
            <img :src="partner.img_url_web" width="160" />
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="key-feature container">
      <div class="card-feature">
        <div class="content">
          <h2 style="line-height: 38px;">Sejak 2020 HiColleagues Berkomitmen sebagai Mitra Akselerasi Digital Terpercaya
            & Kredibel</h2>
          <p style="margin-top: -15px;">Inovasi & Layanan HiColleagues terbukti memberikan dampak signifikan terhadap
            skala pertumbuhan talenta digital dan perusahaan mitra </p>
          <div class="list">
            <div>
              <h2>1500+</h2>
              <p>Total Alumni</p>
            </div>
            <div>
              <h2>20+</h2>
              <p>Recruitement
                Partner</p>
            </div>
            <div>
              <h2>16+</h2>
              <p>Corporate
                Partner</p>
            </div>
            <div>
              <h2>4.8</h2>
              <p>Google
                Rating</p>
            </div>
          </div>
        </div>
        <iframe class="card-image" style="width: 500px; height: 300px"
          src="https://www.youtube.com/embed/7mBp0DYzql4?si=O6Aw9SheRYdJm3a2" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
    <div class="unique-selling-proposition">
      <h1 class="text-center poppins-font usp-title">
        Mengapa HiColleagues menjadi Mitra Akselerasi Digital Terbaik
        <div class="content">
          <div class="row">
            <div class="col-lg-4 items" v-for="(item, idx) in usps" :key="idx">
              <div class="card text-start" style="background-color: white">
                <img :src="item.img" alt="" width="60" height="60" />
                <div class="label">
                  <p class="sub-title">{{ item.name }}</p>
                  <p class="description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </h1>
    </div>
    <div class="usp" style="margin-top: 80px">
      <div class="card-usp">
        <div class="card-img">
          <img src="/img/corporate/collaboration/training.png" alt="" />
        </div>
      </div>
      <div class="usp-container" style="position: relative">
        <h2>Bangun Karir Profesional & Siap Menjadi Talenta Kompeten</h2>
        <div class="card-usp" v-for="(usp, index) in usps2" :key="index">
          <div class="title-container mb-2">
            <div style="width: 30px; height: 30px">
              <component :is="usp.image"></component>
            </div>
            <div>
              <h3>{{ usp.title }}</h3>
              <p>{{ usp.desc }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="wa-cs" @click="redirectReload('https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya')">
          <div class="box-cs" v-if="notifActive">
            <img src="/img/home/close.png" alt="" class="cursor-pointer close-cs" @click="notifActive = false">
            <div style="display: flex; gap: 10px; align-items: center">
              <span style="font-weight: bold; font-family: Poppins">Halo, HiColers!</span>
              <img src="/img/home/shake.png" alt="" style="width: 20px; height: 20px" />
            </div>
            <p style="font-size: 13px">Yuk konsultasikan segala kebutuhanmu bersama HiColleagues di sini!</p>
          </div>
          <img src="/img/home/whatsapp-cs.png" alt="" class="wa-icon">
        </div> -->
      </div>
    </div>
    <Banner :partners="banners" />

    <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <h1 class="text-center my-1">Rekomendasi Program Akselerasi Digital Bootcamp</h1>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in bootcampClass" :key="idx"
            @click="$router.push(`/class/bootcamp/${item.slug_name}`)">
            <div style="height: 240px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.discount | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span style="font-size: 15px">{{
            item.registration_close_date | formatDateFull
          }} WIB</span>
            </div>
          </div>
        </div>
        <div class="btn-blue" @click="$router.push('/class/bootcamp')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong fill="white" />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration">
      <div class="container">
        <h1 class="text-center my-1">Rekomendasi Program Akselerasi Digital</h1>
        <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in accelerations" :key="idx" @click="
            $router.push(`/corporate/acceleration/detail/${item.slug_name}`)
            ">
            <div style="height: 240px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
              <div class="label">
                <img :src="item.collaboration_feed" alt="" style="width: 80%" />
              </div>
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.type">
                  <p>{{ item.type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="item.class_type">
                  <p>{{ item.class_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.discount | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span style="font-size: 15px">{{
            item.registration_close_date | formatDateFull
          }} WIB</span>
            </div>
          </div>
        </div>
        <div class="btn-blue" @click="$router.push('/corporate/acceleration')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong fill="white" />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <h1 class="text-center my-1">Jelajahi Kelas Pelatihan Pilihan</h1>
        <div class="card" style="padding-bottom: 10px">
          <div class="items" v-for="(item, idx) in recommendationClass" :key="idx"
            @click="$router.push(`/class/public-training/detail/${item.slug_name}`)">
            <div style="height: 240px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%;" />
            </div>
            <div class="content" style="border-radius: 80px;">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2>{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" @click="$router.push('/class/public-training')">
          <span class="font-poppins">LIHAT SELENGKAPNYA</span>
          <ArrowLong />
        </div>
      </div>
    </div>
    <div class="list-of-acceleration">
      <div class="container">
        <div style="display: flex; gap: 30px; ">
          <div style="max-width: 35%;">
            <p class="margin-0">Kartu Prakerja</p>
            <h1 class="margin-0" style="line-height: 36px;">Wujudkan Mimpimu Tanpa Mikirin Biaya dengan
              Kartu Prakerja</h1>
            <p class="mt-4">Rekomendasi kelas Kartu Prakerja (Upskilling, New Skilling, & Reskilling) untuk tingkatkan
              keterampilanmu sebagai bekal kerja/wirausaha!</p>
            <div class="btn-stoke-blue" @click="$router.push('/prakerja')" style="margin: 20px 0 0 0;">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
          <div style="width: 65%;">
            <Prakerja :partners="prakerjaClass" />
          </div>
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <div style="display: flex; gap: 30px; ">
          <div style="max-width: 35%;">
            <p class="margin-0">Event</p>
            <h1 class="margin-0" style="line-height: 36px;">Temukan Event Menarik &
              Buat Digital Skill-mu Makin Seru!</h1>
            <p class="mt-4">Rekomendasi event untuk tingkatkan performa menjadi talenta digital kompeten!</p>
            <div class="btn-stoke-blue" @click="$router.push('/event')" style="margin: 20px 0 0 0;">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
          <div style="width: 65%;">
            <Event :partners="events" />
          </div>
        </div>
      </div>
    </div>
    <div class="list-of-acceleration">
      <div class="container">
        <div style="display: flex; gap: 70px; ">
          <div style="max-width: 65%;">
            <div class="card-box">
              <div class="card-1" v-for="(desc1, idx) in descs1" :key="idx">
                <img :src="desc1.img" class="img-2" />
                <h3>{{ desc1.tittle }}</h3>
                <p>{{ desc1.desc }}</p>
              </div>
            </div>
          </div>
          <div style="max-width: 35%;">
            <p class="margin-0">Corporate Service</p>
            <h1 class="margin-0" style="line-height: 35px;">Optimasi Bisnis dengan Transformasi Digital Berkelanjutan
            </h1>
            <p class="mt-4">Berbagai layanan optimalisasi bisnis Anda dengan solusi inovasi digital berkelanjutan,
              mempercepat efisiensi, dan memperkuat daya saing di era digital</p>
            <div class="btn-blue" @click="$router.push('/corporate')" style="margin: 20px 0 0 0;">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong fill="white" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <div style="display: flex; gap: 30px; ">
          <div style="max-width: 40%;">
            <p class="margin-0">Testimoni</p>
            <h1 class="margin-0" style="line-height: 35px;">Cerita Alumni Menuju Talenta Digital Kompeten</h1>
            <div class="btn-stoke-blue" style="margin: 20px 0 0 0;" @click="$router.push('/testimony')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
          <div style="width: 60%;" class="masonry">
            <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
              <p class="text-center">{{ item.feedback }}</p>
              <div class="profile mt-5 d-flex" style="align-items: center">
                <div class="avatar">
                  <v-img :src="$utils.loadImage(item.image_url)" alt="" width="60" height="60" class="avatar-image" />
                </div>
                <div class="testi-profile ml-5">
                  <div class="name">{{ item.name }}</div>
                  <div class="description">
                    {{ item.job }} {{ item.place }}
                  </div>
                  <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="23" height="23"
                    @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="padding: 25px 30px;">
            <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')"  style="margin: 0;background-color: white">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
// import Faq from "@/components/Home/Faq";
// import ExchangeVoucher from "@/components/Prakerja/ExchangeVoucher"
import Banner from '../Banner.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import DateIc2 from "../icons/Date.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
// import Button from '../../others/Button.vue';
import Event from '../Event.vue';
import Prakerja from '../Prakerja.vue'

// icon
import BootcampIc from '../icons/BootcampIc.vue';
import PublicIc from '../icons/Public.vue';
import CorpIc from '../icons/CorpIc.vue';
import PraIc from '../icons/PraIc.vue';
import EventIc from '../icons/Event.vue';
import Instagram from '@/assets/icons/sosmed/Instagram.vue';
import Facebook from '@/assets/icons/sosmed/Facebook.vue';
import Linkedin from '@/assets/icons/sosmed/Linkedin.vue';
import Tiktok from '@/assets/icons/sosmed/Tiktok.vue';
import Youtube from '@/assets/icons/sosmed/Youtube.vue';
import Heart from '../icons/Heart.vue';
import Skill from '../icons/Skill.vue';
import Lamp from '../icons/Lamp.vue';

export default {
  components: {
    // Faq,
    // ExchangeVoucher,
    // Button,
    Instagram,
    Facebook,
    Linkedin,
    Tiktok,
    Youtube,
    BootcampIc,
    PublicIc,
    CorpIc,
    PraIc,
    EventIc,
    DateIc2,
    Banner,
    Prakerja,
    Event,
    Arrow,
    ArrowLong,
    Heart,
    Skill,
    Lamp
  },
  data() {
    return {
      dialog: true,
      mobileView: false,
      notifActive: true,
      is_login_show: false,
      addional_info: [
        {
          count: 1500,
          title: "Total alumni"
        },
        {
          count: 20,
          title: "Hiring Partner"
        },
        {
          count: 16,
          title: "Corporate Partner"
        },
      ],
      descs1: [
        {
          img: "/img/home/f4.png",
          tittle: "Corporate Collaboration",
          desc: "Siap berkolaborasi dengan multipihak untuk mendorong terciptanya talenta digital yang kompeten dan memiliki dampak positif memajukan perekonomian digital Indonesia.",
        },
        {
          img: "/img/home/f1.png",
          tittle: "Corporate Training",
          desc: "Menyediakan pelatihan dengan topik dan silabus yang dapat dikostumisasi serta jadwal fleksibel sesuai dengan kebutuhan perusahaan Anda.",
        },
        {
          img: "/img/home/f2.png",
          tittle: "Talent as a Service",
          desc: "Menyediakan talenta digital mulai dari junior hingga principle yang siap mendukung bisnis dan teknologi di perusahaan Anda dengan mekanisme headhunting dan outsourcing.",
        },
        {
          img: "/img/home/f3.png",
          tittle: "Technology Service",
          desc: "Menyediakan jasa pengembangan website, aplikasi mobile, management system, dan kebutuhan teknologi lainnya untuk membantu percepatan transformasi digital.",
        },
      ],
      iconMenuHome: [
        {
          img: '/img/home/bootcamp-logo.png',
          name: 'Bootcamp',
          link: "/class/bootcamp"
        },
        {
          img: '/img/home/public-training-logo.png',
          name: 'Public Training',
          link: "/class/public-training"
        },
        {
          img: '/img/home/kartu-prakerja.png',
          name: 'Kartu Prakerja',
          link: "/prakerja"
        },
        {
          img: '/img/home/logo-highlight.png',
          name: 'Kampus Merdeka',
          link: "/kampusmerdeka"
        },
        {
          img: '/img/home/corporate-service-logo.png',
          name: 'Corporate Service',
          link: "/corporate"
        },
      ],
      partners: [],
      benefits: [
        { image: '/img/home/ic_kurikulum.png', title: 'Kurikulum Terupdate', desc: 'Kurikulum sesuai dengan industri digital saat ini', id: 1 },
        { image: '/img/home/ic_instruktur.png', title: 'Instruktur Berpengalaman', desc: 'Instruktur berkompetensi dan berpengalaman sesuai bidangnya', id: 2 },
        { image: '/img/home/ic_pelatihan_variatif.png', title: 'Jenis Pelatihan Bervariatif', desc: 'Kami memiliki lebih dari 50 judul pelatihan', id: 3 },
        { image: '/img/home/ic_praktical.png', title: 'Pembelajaran secara Praktikal', desc: '80% materi yang diajarkan oleh instruktur bersifat praktikal', id: 4 },
        { image: '/img/home/ic_problem_solving.png', title: 'Problem Solving & Critical Thinking', desc: 'Memecahkan masalah secara sistematis dan komprehensif', id: 5 },
        { image: '/img/home/ic_guarantee.png', title: '100% Pelatihan Bergaransi', desc: 'Dijamin belajar sampai bisa', id: 6 },
      ],
      publicClass: [
        { message: 'Lebih dari satu peserta (max 10)' },
        { message: 'Jadwal kelas telah ditentukan' },
        { message: 'Diajarkan langsung oleh instruktur' },
        { message: 'Gratis Survival Kit' },
        { message: 'Sertifikat' }
      ],
      privateClass: [
        { message: 'Hanya satu peserta' },
        { message: 'Dapat menentukan jadwal kelas' },
        { message: 'Diajarkan langsung oleh instruktur' },
        { message: 'Gratis Survival Kit' },
        { message: 'Sertifikat' }
      ],
      bootcampClass: [],
      prakerjaClass: [],
      recommendationClass: [],
      competences: [
        { image: '/img/other/ic_skill_knowledge.png', title: 'Skill & Knowledge', description: 'Kemampuan untuk menerapkan pengetahuan dalam pekerjaan sehingga hasil kerja yang diinginkan dapat tercapai dengan lebih efisien dan efektif', id: 1 },
        { image: '/img/other/ic_attitudes.png', title: 'Attitudes', description: 'Perilaku yang dipelajari, karakteristik kecerdasan emosional, dan keyakinan yang ditunjukkan individu yang memengaruhi gagasan, orang, dan situasi', id: 2 },
        { image: '/img/other/ic_abilities.png', title: 'Abilities', description: 'Potensi seseorang individu untuk menguasai keahlian dalam melakukan beragam tugas dalam suatu pekerjaan atau suatu penilaian atas tindakan seseorang', id: 3 },
      ],
      banners: [],
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      usps: [
        {
          img: "/img/home/c1.png",
          name: "Kurikulum Up-to-Date",
          description: "Kami menyediakan kurikulum pelatihan dan sertifikasi yang up to date sesuai kebutuhan pasar kerja dan industri.",
        },
        {
          img: "/img/home/c2.png",
          name: "Instruktur Kompeten & Tersertifikasi",
          description: "Instruktur kami merupakan praktisi yang berpengalaman, kompeten, dan tersertifikasi. ",
        },
        {
          img: "/img/home/c3.png",
          name: "Jenis Pelatihan Variatif",
          description: "Memiliki variasi pelatihan di bidang teknologi, soft skill, dan bahasa untuk pemula hingga profesional.",
        },
        {
          img: "/img/home/c4.png",
          name: "Pembelajaran secara Praktikal",
          description: "80% materi diajarkan instruktur secara praktikal dan intensif untuk melatih memecahkan masalah secara sistematis dan komprehensif.",
        },
        {
          img: "/img/home/c5.png",
          name: "Sertifikat & Portfolio",
          description: "Peserta akan mendapatkan sertifikat penyelesaian dan sertifikat kompetensi, serta memiliki project portfolio sesuai dengan pelatihan yang diikuti.",
        },
        {
          img: "/img/home/c6.png",
          name: "Pelatihan Bergaransi",
          description: "Peserta dibimbing menjadi talenta digital profesional.",
        },
      ],
      usps2: [
        {
          image: Lamp,
          title: "Knowledge",
          desc: "Penerapan pengetahuan dalam pekerjaan memberikan dampak pada hasil kerja yang diinginkan dapat tercapai dengan lebih efisien dan efektif.",
        },
        {
          image: Skill,
          title: "Skill & Abilities",
          desc: "Menguasai keterampilan digital untuk mempercepat laju pengembangan SDM yang berkualitas.",
        },
        {
          image: Heart,
          title: "Attitudes",
          desc: "Karakteristik Individu dalam pekerja berupa perilaku, karakteristik, kecerdasan emosional & keyakinan yang memengaruhi gagasan, orang dan situasi.",
        },
      ],
      soscialMedia: [
        {
          name: "instagram",
          img: Instagram,
          link: "https://instagram.com/hicolleagues"
        },
        {
          name: "facebook",
          img: Facebook,
          link: "https://www.facebook.com/hicolleagues/"
        },
        {
          name: "linkedin",
          img: Linkedin,
          link: "https://www.linkedin.com/company/hicolleagues?originalSubdomain=id"
        },
        {
          name: "tiktok",
          img: Tiktok,
          link: "https://www.tiktok.com/@hicolleagues"
        },
        {
          name: "youtube",
          img: Youtube,
          link: "https://www.youtube.com/@HiColleagues"
        },
      ],
      testimonies: [],
      accelerations: [],
      questions: [],
      events: []
    }
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },
  },
  methods: {
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, '_blank')
      } else {
        this.$router
          .push({ path: e })
          .then(() => { this.$router.go() })
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=HOME&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    toSosmed(link) {
      window.open(link)
    },
    goToCustomerService() {
      window.location.href = 'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=HOME&is_active=true&page=1&per_page=4');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get("/v1/event?is_active=true");
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules ? item.session_schedules
            ? item.session_schedules
            : [
              {
                date_end: "",
                date_start: "",
                end_time: "",
                start_time: "",
              },
            ] : [{
              date_end: "",
              date_start: "",
              end_time: "",
              start_time: "",
            }],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
              desktop: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
              mobile: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
            },
          expired: [],
        }));
      } catch (err) {
        console.log(err);
      }
    },
    async getBanners() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/banner?category=GENERAL&is_active=true")
        .then((response) => {
          this.banners = response.data.data.banners;
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    async getBootcampClass() {
      try {
        const resp = await this.$http.get('/v1/class/category/5?is_main=true')
        this.bootcampClass = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    async getPrakerjaClass() {
      try {
        const resp = await this.$http.get('/v1/class/category/4?is_main=true')
        this.prakerjaClass = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    async getPublicClass() {
      try {
        const resp = await this.$http.get('/v1/class/category/1?is_main=true')
        this.recommendationClass = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    async getAccelerationClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/6");
        this.accelerations = resp.data.data.classes;
        this.accelerations.splice(3, 1)
      } catch (err) {
        console.log(err);
      }
    },
    duptlicatePartners() {
      
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=CORPORATE")
        this.partners = resp.data.data.logos
        this.partners = [...this.partners, ...this.partners, ...this.partners, ...this.partners]

      } catch (err) {
        console.log(err);
      }
    },
    goToHomePage() {
      this.$router
        .push({ path: '/' })
        .then(() => { this.$router.go() })
    },
    goToLoginPage() {
      this.$router
        .push({ path: '/login' })
        .then(() => { this.$router.go() })
    },
    goToClassBootcamp(slug_name) {
      this.$router.push('/class/public-training/detail/' + slug_name)
      sessionStorage.setItem('nav-item-active', 0)
      window.location.reload()
    },
    goToRouter(link) {
      this.$router
        .push({ path: link })
        .then(() => { this.$router.go() })
    },
    goToClass() {
      this.$router
        .push({ path: '/class/public-training' })
        .then(() => { this.$router.go() })
    },
    goToEvent() {
      this.$router
        .push({ path: '/event' })
        .then(() => { this.$router.go() })
    },
    goToPrakerja() {
      this.$router
        .push({ path: '/prakerja' })
        .then(() => { this.$router.go() })
    },
    goToCorporateTraining() {
      window.open('/corporate')
    },
    goToBootcamp() {
      this.$router
        .push({ path: '/class/bootcamp' })
        .then(() => { this.$router.go() })
    },
    minimize(text) {
      return text.length > 290 ? text.slice(0, 290) + "..." : text;
    },
    goToTalentAsAService() {
      this.$router
        .push({ path: '/talent-service' })
        .then(() => { this.$router.go() })
    },
    async toCheckout(data) {
      this.isLoading = true;
      await this.$http
        .get("/v1/class-detail/" + data.slug_name)
        .then((response) => {
          const classDetail = response.data.data.class_detail;
          const reqCheckout = JSON.stringify({
            class_id: classDetail.class_id,
            class_name: classDetail.name,
            price: classDetail.price,
            level: classDetail.level,
            session: classDetail.session_total,
            hour: classDetail.hours,
            image_url: classDetail.small_image_url,
            class_type: "PRAKERJA_CLASS",
            days: classDetail.days,
            methode: classDetail.method,
            category_id: classDetail.category_id,
          });

          localStorage.clear();
          localStorage.setItem("checkout", reqCheckout);
          this.isLoading = false;
          window.location.href = "/checkout";
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err.response);
        });
    },
  },
  mounted() {
    this.getBanners()
    this.getBootcampClass()
    this.getPrakerjaClass()
    this.getPublicClass()
    this.getClientLogo()
    this.getFaq()
    this.getDataEvents()
    this.getAccelerationClass()
    this.getTestimoni()
  }
}

</script>

<style src="./home.css" scoped></style>
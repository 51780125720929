<template>
	<div>
		<div class="hero">
			<div class="content">
				<h1>
					Tingkatkan Kompetensi Era<br />
					Digitalisasi dengan Kelas Pilihan
				</h1>
				<p>
					<b>#MulaiLangkahHebat</b> dengan temukan kelas terbaik yang
					cocok untuk tingkatkan kompetensi<br />
					dalam transformasi digital berkelanjutan dan siap jadi
					talenta profesional!
				</p>
				<div class="btn-cta">
					<button
						class="now"
						@click="scrollToSection('public-training-class')">
						LIHAT KELAS
					</button>
					<button class="call" @click="sendToWa">
						KONSULTASI GRATIS
					</button>
				</div>
			</div>
			<div class="card-img">
				<img
					src="/img/publictraining/hero.png"
					width="556"
					height="392"
					alt="public-training-hero" />
			</div>
		</div>

		<div class="benefits text-center container">
			<h3 class="sub-title-span">
				HiColleagues Pilihan Tepat Akselerasi Skill Digital
			</h3>
			<div class="card-benefits">
				<div
					v-for="n in benefits"
					:key="n.id"
					class="content-card mr-2 ml-2">
					<div class="left-span">
						<img
							:src="n.image"
							width="80"
							height="80"
							style="border-radius: 50%" />
					</div>
					<div>
						<div class="title-2" style="text-align: left">
							{{ n.title }}
						</div>
						<div class="desc mt-2" style="text-align: left">
							{{ n.desc }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			id="public-training-class"
			class="text-center container"
			style="margin-top: 104px">
			<h3 class="sub-title-span">
				Jelajahi Kategori Kelas Akselerasi Digital
			</h3>
			<div
				class="d-flex container justify-center category-class"
				style="margin-top: 48px">
				<div
					v-for="(data, idx) in actionMethod"
					:key="idx"
					class="inactive category-class-card"
					@click="changeCategory(data.slug_name)"
					:class="{ active: layout == data.slug_name }">
					<img
						:src="data.icon"
						alt="category-class-icon"
						width="56"
						height="56" />
					<div class="category-class-detail">
						<div class="category-class-info">
							<h4>{{ data.title }}</h4>
							<p>{{ data.desc }}</p>
						</div>
						<Arrow :width="32" :height="32" direct="right" />
					</div>
				</div>
			</div>
		</div>

		<div class="text-center container" style="margin-top: 104px">
			<h3 class="sub-title-span">Rekomendasi Kelas HiColleagues</h3>
			<div v-if="classRecommends.length > 0">
				<div class="class-list-container mx">
					<CardClass
						v-for="n in classRecommends"
						:key="n.id"
						:onClick="handleCardClick"
						:data="n" />
				</div>
			</div>
			<div v-else>
				<div class="text-center" style="margin: 20px 0">
					<img src="/img/other/ic_coming_soon.png" height="300" />
				</div>
			</div>
		</div>

		<div
			class="text-center explore-class-container"
			style="margin-top: 104px">
			<h3 class="sub-title-span" style="margin-bottom: 48px">
				Jelajahi Kelas HiColleagues
			</h3>
			<div
				v-for="(i, idx) in subCategories"
				:key="i.name"
				class="container-sub-category">
				<hr
					size="2"
					color="#ACAFBF"
					style="margin-bottom: 64px; margin-top: 64px"
					v-if="!idx == 0" />
				<h4 class="title-sub-category" v-if="i.name">
					{{ i.name }}
				</h4>
				<div class="class-list-container">
					<CardClass
						v-for="n in i.recommendationClass"
						:key="n.id"
						:onClick="handleCardClick"
						:data="n" />
				</div>
				<div v-if="!i.recommendationClass.length">
					<div class="text-center" style="margin: 20px 0">
						<img src="/img/other/ic_coming_soon.png" height="300" />
					</div>
				</div>
			</div>
		</div>

		<div class="faq">
			<div class="content">
				<div class="row">
					<div class="col-lg-3">
						<div class="header" style="margin-bottom: 20px">
							<h1>Frequently Asked Question</h1>
						</div>
					</div>
					<div class="col-lg-9" style="margin-top: -10px">
						<v-expansion-panels
							flat
							class="faq-card"
							style="padding: 20px">
							<v-expansion-panel
								v-for="(item, index) in questions"
								:key="index"
								style="
									border-radius: 20px;
									padding: 0;
									margin-bottom: 10px;
									background-color: #f7f9fd;
								">
								<v-expansion-panel-header
									disable-icon-rotate
									@click="openFaq(index)">
									<h2 style="margin: 25px 0; font-size: 18px; line-height: 24px;">
										{{ item.title }}
									</h2>
									<template v-slot:actions>
										<div class="icon">
											<Arrow :down="!item.active" />
										</div>
									</template>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div
										v-html="item.description"
										style="width: 95%"></div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
						<div
							class="btn-stoke-gray cursor-pointer"
							@click="$router.push('/faq')">
							<span class="font-poppins">LIHAT SELENGKAPNYA</span>
							<ArrowLong fill="#575966" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Arrow from '@/assets/icons/Arrow2.vue';
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import CardClass from './CardClass.vue';

export default {
	components: {
		Arrow,
		ArrowLong,
		CardClass,
	},
	data() {
		return {
			layout: 'teknologi',
			categoryList: [
				{
					id: 1,
					name: "teknologi"
				},
				{
					id: 2,
					name: "bahasa"
				},
				{
					id: 3,
					name: "soft-skill"
				},
			],
			actionMethod: [
				{
					id: 1,
					slug_name: "teknologi",
					title: 'Kelas Teknologi',
					desc: 'Pelajari teknologi terkini dan tingkatkan keahlian digital Anda.',
					icon: '/img/publictraining/ic_tech.png',
				},
				{
					id: 2,
					slug_name: "bahasa",
					title: 'Kelas Bahasa',
					desc: 'Kuasi bahasa baru untuk memperluas peluang komunikasi global.',
					icon: '/img/publictraining/ic_language.png',
				},
				{
					id: 3,
					slug_name: "soft-skill",
					title: 'Kelas Soft Skill',
					desc: 'Kembangkan kemampuan interpersonal untuk sukses profesional.',
					icon: '/img/publictraining/ic_softskill.png',
				},
			],
			benefits: [
				{
					title: 'Intensive Program',
					desc: 'Durasi program 1 bulan secara efisien mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri.',
					image: '/img/bootcamp/ic_intensive_program.png',
				},
				{
					title: 'Competent Trainer',
					desc: 'Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan.',
					image: '/img/bootcamp/ic_support_3.png',
				},
				{
					title: 'Learning Management System',
					desc: 'Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat.',
					image: '/img/bootcamp/ic_support_1.png',
				},
				{
					title: 'E-Certificate',
					desc: 'Sertifikat sebagai bukti validasi peserta telah menyelesaikan program & diakui oleh industri sebagai nilai pendukung dalam berkarir.',
					image: '/img/bootcamp/ic_sertifikat_kompetensi.png',
				},
				{
					title: 'Job Connector',
					desc: 'Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta program.',
					image: '/img/bootcamp/ic_support_2.png',
				},
				{
					title: 'Installment Option Available',
					desc: 'Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta.',
					image: '/img/bootcamp/ic_installment.png',
				},
			],
			subCategories: [],
			classRecommends: [],
			// dummy
			recommendationClass: [],
			questions: [],
		};
	},
	methods: {
		changeCategory(categoryId) {
			this.$router.push(`/class/public-training/${categoryId}`);
            window.location.reload()
		},
		async getDataClassRecomendation() {
			await this.$http
				.get(`/v1/class?is_recommend=true`)
				.then((response) => {
					this.classRecommends = response.data.data.classes
				})
				.catch((err) => console.error(err.response))
		},
		
		async getDataClass() {
			if (this.layout == 'teknologi') {
				this.subCategories = [
					{
						name: 'Kelas Programming',
						recommendationClass: [],
					},
					{
						name: 'Kelas Data',
						recommendationClass: [],
					},
					{
						name: 'Kelas Desain',
						recommendationClass: [],
					},
				];
			} else {
				this.subCategories = [
					{
						name: '',
						recommendationClass: [],
					},
				];
			}
			const categoryId = this.categoryList.find((item) => item.name === this.layout)
			await this.$http
				.get(`/v1/class/category/${categoryId.id}`)
				.then((response) => {
					response.data.data.classes.forEach((element) => {
						if (this.layout == 'teknologi') {
							if (element.class_sub_category.id == 1) {
								this.subCategories[0].recommendationClass.push({
									id: parseInt(element.id),
									name: element.name,
									thumbnail_url: element.thumbnail_url,
									description: element.description,
									level: element.level,
									method: element.method,
									slug_name: element.slug_name,
								});
							} else if (element.class_sub_category.id == 2) {
								this.subCategories[1].recommendationClass.push({
									id: parseInt(element.id),
									name: element.name,
									thumbnail_url: element.thumbnail_url,
									description: element.description,
									level: element.level,
									method: element.method,
									slug_name: element.slug_name,
								});
							} else {
								this.subCategories[2].recommendationClass.push({
									id: parseInt(element.id),
									name: element.name,
									thumbnail_url: element.thumbnail_url,
									description: element.description,
									level: element.level,
									method: element.method,
									slug_name: element.slug_name,
								});
							}
						} else {
							this.subCategories[0].recommendationClass.push({
								id: parseInt(element.id),
								name: element.name,
								thumbnail_url: element.thumbnail_url,
								description: element.description,
								level: element.level,
								method: element.method,
								slug_name: element.slug_name,
							});
						}
					});
				})
				.catch((err) => console.error(err.response));
		},
		handleCardClick(slugName) {
			this.$router.push('/class/public-training/detail/' + slugName);
		},
		sendToWa() {
			window.open(
				'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya',
			);
		},
		scrollToSection(sectionId) {
			const element = document.getElementById(sectionId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		},
		async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=PUBLIC_TRAINING&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
		openFaq(idx) {
			const valDef = this.questions[idx].active;
			this.questions.forEach((e) => {
				e.active = false;
			});
			this.questions[idx].active = !valDef;
		},
	},
	mounted() {
		this.layout = this.$route.params.category ?? 'teknologi';
		this.getFaq()
		this.getDataClass();
		this.getDataClassRecomendation()
	},
};
</script>

<style src="./css/publicTraining.css" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f1f6ff","padding-top":"64px"}},[_c('h2',{staticStyle:{"font-weight":"700","font-size":"18px","line-height":"28px","color":"#36373f","text-align":"center","width":"328px","margin":"0px auto 24px auto"}},[_vm._v(" Jelajahi Kelas HiColleagues ")]),_vm._l((_vm.subCategories),function(i){return _c('div',{key:i.name,staticClass:"bg"},[_c('h4',{staticStyle:{"margin-bottom":"24px","font-weight":"700","font-size":"16px","line-height":"24px","color":"#36373f"}},[_vm._v(" "+_vm._s(i.name)+" ")]),_c('div',{staticStyle:{"display":"flex","overflow":"auto","gap":"16px","padding":"0px 16px 20px 16px"}},_vm._l((i.recommendationClass),function(n){return _c('div',{key:n.id,staticClass:"content-card-3"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/class/public-training/detail/' + n.slug_name}},[_c('div',{staticStyle:{"height":"164px","overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","min-height":"164px","border-top-left-radius":"16px","border-top-right-radius":"16px"},attrs:{"src":n.thumbnail_url}})]),_c('div',{staticStyle:{"padding":"16px"}},[_c('div',{staticStyle:{"display":"flex","gap":"4px","margin":"16px 0px 8px 0px"}},[_c('div',{style:({
                  textAlign: 'center',
                  backgroundColor: '#F1F6FF',
                  borderRadius: '30px',
                })},[_c('p',{staticStyle:{"font-weight":"400","font-size":"11px","line-height":"14px","margin":"0px","padding":"2px 8px","color":"#36373f"}},[_vm._v(" "+_vm._s(n.level)+" ")])]),_c('div',{style:({
                  textAlign: 'center',
                  backgroundColor: '#FAF6E7',
                  borderRadius: '30px',
                })},[_c('p',{staticStyle:{"font-weight":"400","font-size":"11px","line-height":"14px","margin":"0px","padding":"2px 8px","color":"#36373f"}},[_vm._v(" "+_vm._s(n.method || 'Online/Offline')+" ")])]),(n.count_session)?_c('div',{style:({
                  textAlign: 'center',
                  backgroundColor: '#faeceb',
                  borderRadius: '30px',
                })},[_c('p',{staticStyle:{"font-weight":"400","font-size":"11px","line-height":"14px","margin":"0px","padding":"2px 8px","color":"#36373f"}},[_vm._v(" "+_vm._s(n.count_session)+" ")])]):_vm._e()]),_c('div',{staticStyle:{"height":"35px"}},[_c('div',{staticClass:"title-2",staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(n.name))])]),_c('div',{staticStyle:{"height":"55px"}},[_c('div',{staticClass:"desc mt-2",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(n.description)+" ")])])])])],1)}),0),(!i.recommendationClass.length)?_c('div',[_vm._m(0,true)]):_vm._e()])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center",staticStyle:{"margin":"20px 0"}},[_c('img',{attrs:{"src":"/img/other/ic_coming_soon.png","height":"300"}})])
}]

export { render, staticRenderFns }
<template>
  <div class="main-container" >
    <img :src="event.img_urls.mobile.product_detail" class="img-ctn-mobile" />
    <div class="banner-content" ref="parent">
      <div style="padding: 0px 20px;">
        <div class="event-ctn-label">
          <span style="
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            background-color: #faeceb;
          ">{{ event.category.name }}</span>
        </div>
        <h2 style="margin: 0px" class="line-height-normal">{{ event.name }}</h2>

        <p class="description" v-html="event.description"></p>
        <h2 style="font-size: 16px; font-weight: 700; line-height: 24px" class="mt-10">
          Mekanisme Kelas
        </h2>
        <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr))" class="mt-5">
          <div>
            <p style="
              margin: 0px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #575966;
            ">
              Level Pelatihan
            </p>
            <p style="
              margin: 0px;
              font-size: 13px;
              font-weight: 600;
              line-height: 18px;
            ">
              {{ event.level }}
            </p>
          </div>
          <div>
            <p style="
              margin: 0px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #575966;
            ">
              Kuota Peserta
            </p>
            <p style="font-size: 13px; font-weight: 600; line-height: 18px">
              {{ event.participant_quota > 0 ? `${event.participant_quota} peserta` : 'Tidak terbatas' }}
            </p>
          </div>
          <div>
            <p style="
              margin: 0px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #575966;
            ">
              Total Sesi
            </p>
            <p style="font-size: 13px; font-weight: 600; line-height: 18px">{{ event.total_session }}</p>
          </div>
          <div>
            <p style="
              margin: 0px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #575966;
            ">
              Metode Pelatihan
            </p>
            <p style="
              margin: 0px;
              font-size: 13px;
              font-weight: 600;
              line-height: 18px;
            ">
              {{ event.method | toMethode }}
            </p>
          </div>
        </div>
        <div class="stroke-gray-box" v-if="event.method === 'Offline'">

          <div class="event-mekanisme-detail">
            <div class="title-mechanism"><span>Lokasi Pelaksanaan</span></div>
            <div class="sub-title-mechanism">
              <h4>{{ event.location_name }}</h4>
              <p>{{ event.location_address }}</p>
              <p v-if="event.location_map">
                <a :href="event.location_map">{{ event.location_map }}</a>
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2 style="font-size: 16px; font-weight: 700; line-height: 24px" class="mt-10">
            Jadwal Tayang
          </h2>
          <div style="
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: #f7f9fd;
            margin-bottom: 16px;
            padding: 12px;
            border-radius: 8px;
          " v-for="(item, idx) in event.session_schedules" :key="`c-${idx}`">
            <img src="/img/icons/Cheack2.png" alt="Bullet" />
            <div style="display: flex; justify-content: space-between; gap: 5px; width: 100%">
              <p style="
                margin: 0px;
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                color: #36373f;
              ">
                <span>{{ moment(item.date_start) }}
                  {{
                    item.date_end ? "- " + moment(item.date_end) : ""
                  }}</span>
              </p>

              <p style="
                margin: 0px;
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                color: #36373f;
              ">
                {{ item.start_time }} - {{ item.end_time }} WIB
              </p>
            </div>
          </div>
        </div>

        <h2 style="font-size: 16px; font-weight: 700; line-height: 24px" class="mt-10" v-if="event.syllabuses.length">
          Materi Silabus
        </h2>
        <div style="
          background-color: #f7f9fd;
          padding: 12px 22px;
          border-radius: 8px;
          margin-bottom: 16px;
        " v-for="(n, idx) in event.syllabuses" :key="`d-${idx}`">
          <div style="
            border-bottom: 1px solid #acafbf;
          ">
            <h3 style="font-weight: 600; font-size: 14px; line-height: 20px">
              Sesi {{ idx + 1 }}
            </h3>
            <div style="margin-bottom: 8px;" v-if="event.session_schedules && event.session_schedules[idx]">
              <p style="
                margin: 0px;
                font-size: 13px;
                line-height: 22px;
                font-weight: 400;
                color: #36373f;
              ">
                {{ moment(event.session_schedules[idx].date_start) }}
                {{
                  event.session_schedules[idx].date_end ? "- " + moment(event.session_schedules[idx].date_end) : ""
                }}
              </p>
              <p style="
                margin: 0px;
                font-size: 13px;
                line-height: 22px;
                font-weight: 400;
                color: #36373f;
              ">
                {{ event.session_schedules[idx].start_time }} -
                {{ event.session_schedules[idx].end_time }} WIB
              </p>
            </div>
            <div style="margin-bottom: 8px;" v-else>
              <p style="
                margin: 0px;
                font-size: 13px;
                line-height: 22px;
                font-weight: 400;
                color: #36373f;
              ">
                Tidak ada jadwal tersedia
              </p>
              </div>
          </div>
          <div style="padding: 12px 16px">
            <ul style="margin-top: 8px">
              <li style="
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #575966;
              " class="silabus-item" v-for="(item2, idx2) in n" :key="`e-${idx2}`">
                {{ item2 }}
              </li>
            </ul>
          </div>
        </div>
        <h2 style="font-size: 16px; font-weight: 700; line-height: 24px" class="mt-10">
          Narasumber
        </h2>
        <div class="narasumber" v-for="(item, idx) in event.instructors" :key="`a-${idx}`">
          <div style="width: 100%; margin: auto">
            <img :src="$utils.loadImage(item.image_url)" alt="Profile" class="narasumber-img" />
            <div style="margin: 8px 0px 16px 0px">
              <h2 style="
                margin: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
              ">
                {{ item.name }}
              </h2>
              <span class="narasumber-work">{{ item.position }}
                {{ item.company ? "at " + item.company : "" }}</span>
            </div>
            <p class="description-2">{{ item.description }}</p>
          </div>
        </div>

        <h2 style="font-size: 16px; font-weight: 700; line-height: 24px" class="benefit mt-10">
          Benefit
        </h2>
        <div v-for="(item, idx) in event.benefits" :key="`b-${idx}`" class="benefit-list">
          <img src="/img/icons/Cheack2.png" alt="Bullet" />
          <span style="
            font-size: 13px;
            line-height: 22px;
            font-weight: 400;
            color: #36373f;
          ">{{ item }}</span>
        </div>
<br>
      </div>
    <div class="box-checkout" ref="fixedBox" >
      <div>
        <div class="event-price" v-if="!checkIsEarlyBird(event)">
          <p class="regular-price-label">Harga Reguler</p>
          {{ event.price ? toCurrency(event.price) : "GRATIS" }}
        </div>
        <div v-else class="mb-2">
          <span class="early-bird-label">{{ event.label_discount }}</span>
          <br />
          <del class="discount-price mr-2">{{ toCurrency(event.price) }}</del>
          <span class="event-price mr-2">
            {{
              event.price - event.discount_price
                ? toCurrency(event.price - event.discount_price)
                : "GRATIS"
            }}
          </span>
        </div>
      </div>

      <div class="button-checkout" v-if="event.expired.length && !isFullRegistrant">
        <Button name="DAFTAR" width="100%" @click="() => toCheckout()"></Button>
      </div>
      <div class="event-due-date" v-if="event.expired.length">
        <div class="event-due-date-ctn">
          <span>{{ event.expired[0] }}</span>
          <span>Hari</span>
        </div>
        <span>:</span>
        <div class="event-due-date-ctn">
          <span>{{ event.expired[1] }}</span>
          <span>Jam</span>
        </div>
        <span>:</span>
        <div class="event-due-date-ctn">
          <span>{{ event.expired[2] }}</span>
          <span>Menit</span>
        </div>
        <span>:</span>
        <div class="event-due-date-ctn">
          <span>{{ event.expired[3] }}</span>
          <span>Detik</span>
        </div>
      </div>
      <div class="event-due-date" v-else>
        <div class="event-due-date-ctn">EXPIRED</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from "moment";
import Button from "@/components/others/Button.vue";

export default {
  name: "EventDetailMobile",
  components: {
    Button,
  },
  data() {
    return {
      event: {
        name: "",
        learning_url: "",
        caption: "",
        category: {
          name: "",
        },
        img_urls: {
          desktop: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
          mobile: {
            product_detail: "",
            purchase_detail: "",
            thumbnail: "",
          },
        },
        description: "",
        method: "",
        total_session: 0,
        day_session: "",
        labels: [],
        benefits: [],
        instructors: [
          {
            name: "",
          },
        ],
        registration_close_date: "",
        price: 0,
        syllabuses: [],
        session_schedules: [
          [
            {
              date_start: "",
            },
          ],
        ],
        is_active: false,
        expired: [],
      },
      isAlertErr: false,
      scrollTimeout: null,
      isFullRegistrant: false,
      isFixed: true,
    };
  },
  methods: {
    async getEventDetail() {
      try {
        const resp = await this.$http.get("/v2/event/" + this.$route.params.id);
        const item = resp.data.data.event;
        this.event = {
          ...item,
          session_schedules: item.session_schedules.length
            ? item.session_schedules
            : [
              {
                date_end: "",
                date_start: "",
                end_time: "",
                start_time: "",
              },
            ],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
              desktop: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
              mobile: {
                product_detail: "",
                purchase_detail: "",
                thumbnail: "",
              },
            },
          expired: [],
        };
        this.getTotalRegistran();
      } catch (err) {
        console.log(err);
      }
    },
    async getTotalRegistran() {
      try {
        const resp = await this.$http.get(
          `/v1/event/${this.event.id}/registrant`
        );
        this.total_registrants = resp.data.data.total_registrants;
        if (
          this.total_registrants >= this.event.participant_quota &&
          this.event.participant_quota !== 0
        ) {
          this.isFullRegistrant = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toCheckout() {
      if (!this.event.expired.length) {
        this.isAlertErr = true;
        return;
      }
      this.$router.push("/checkout/event/" + this.event.slug_name);
    },
    toKonsultasi() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    checkIsEarlyBird(item) {
      if (
        new Date(item.start_discount_date) <= new Date() &&
        new Date(item.end_discount_date) >= new Date()
      ) {
        return true;
      } else {
        return false;
      }
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        const end = new Date(this.event.registration_close_date);
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {
          clearInterval(timer);
          this.event.expired = [];
          return;
        }
        days = Math.floor(distance / _day);
        hours = Math.floor((distance % _day) / _hour);
        minutes = Math.floor((distance % _hour) / _minute);
        seconds = Math.floor((distance % _minute) / _second);
        this.event.expired = [days, hours, minutes, seconds];
      };
      timer = setInterval(showRemaining, 1000);
    },
  
  },
  mounted() {
    this.getEventDetail();
    this.countDownTimer();
  },
  metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.event.name,
      htmlAttrs: { lang: "id", amp: true },
      meta: [
        { name: "description", content: this.event.description },
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.event.name,
            "url": thisPath
          }
        }
      ]
    };
  },
};
</script>
<style src="./eventDetail.css" scoped></style>

import { render, staticRenderFns } from "./Qoute.vue?vue&type=template&id=114d18ba"
import script from "./Qoute.vue?vue&type=script&lang=js"
export * from "./Qoute.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <div style="background:#F1F6FF;">
        <div style="margin-left:16px"><img src="/img/logo.png" style="width:180px;height:120px;" @click="goToHomePage">
        </div>
        <div class="d-flex" style="align-items: center;justify-content: center;"><img src="/img/other/login-img.png"
                width="200px" height="147px"></div>
        <div class="title">
            <h3>Mulai <span class="blue-title font-poppins">Langkah Hebat</span></h3>
            <img src="/img/other/line-blue.png" width="170px"
                style="position:absolute;margin-left:-50px;margin-top:-10px">
        </div>

        <div class="title">
            <h3>untuk Persiapan Masa Depanmu</h3>
        </div>
        <div class="sub-title"><span class="font-poppins">Siap jadi talenta profesional melalui program upskilling /
                reskilling dalam Transformasi Digital</span></div>

        <div class="body-form">
            <v-form style="padding:32px">
                <v-alert type="error" class="mt-5" v-if="show_alert_error">
                    {{ error_message }}
                </v-alert>

                <v-text-field label="Email" v-model="email" @input="lowerWord" @keypress="blockSpace" filled clearable
                    placeholder="Email" class="custom-text-field" :rules="[rules.required, rules.emailFormat]" required>
                </v-text-field>

                <v-text-field :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'" label="Password" filled clearable placeholder="Password"
                    style="margin-top:20px" class="custom-text-field" :rules="[rules.required, rules.min]"
                    @click:append="show_password = !show_password" v-model="password" required>
                </v-text-field>

                <div class="forgot-password"><span class="font-poppins">Lupa Sandi?</span></div>


                <v-btn color="#000080" width="100%" @click="login"
                    style="border-radius: 16px;margin-top: 20px;margin-bottom: 20px;padding: 22px;">
                    <span class="white--text font-weight-bold font-poppins">Masuk</span>
                </v-btn>

                <div class="notes"><span class="font-poppins">Dengan masuk ke dalam akun, kamu menyetujui <span
                            class="font-poppins" style="cursor:pointer;color:#162CA2;font-weight: 600;"
                            @click="goToTermCondition">Syarat & Ketentuan</span> dan <span class="font-poppins"
                            style="cursor:pointer;color:#162CA2;font-weight: 600;" @click="goToPrivacyPolicy">Kebijakan
                            Privasi</span> Hicolleagues.</span></div>
            </v-form>

            <div class="notes" style="margin:30px 0px"><span class="font-poppins">@2023 PT Generasi Anak Muda
                    Berkarya</span></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            show_password: false,
            error_message: '',
            show_alert_error: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
                emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address'
            },
        }
    },
    methods: {
        blockSpace(event) {
            const char = String.fromCharCode(event.keyCode);
            if (char === ' ') {
                event.preventDefault();
            }
        },
        lowerWord() {
            this.email = this.email.toLowerCase()
        },
        goToHomePage() {
            window.location.href = '/';
        },
        goToPrivacyPolicy() {
            window.location.href = '/privacy-policy';
        },
        goToTermCondition() {
            window.location.href = '/term-condition';
        },
        async login() {
            await this.$http
                .post("/v1/login", {
                    email: this.email,
                    password: this.password,
                })
                .then((response) => {
                    // this.setEvent('click-button-login', 'user', 'sign in', this.email)
                    // localStorage.setItem("user", JSON.stringify(response.data.data.user))
                    // setHeader(response.data.data.user.token)
                    // this.$forceUpdate()

                    this.show_alert_error = false
                    if (process.env.NODE_ENV == 'development') {
                        window.location.href = 'http://localhost:8082/home/' + response.data.data.user.token
                    } else {
                        window.location.href = 'https://lms.hicolleagues.com/home/' + response.data.data.user.token
                    }
                })
                .catch(() => {
                    this.show_alert_error = true
                    this.error_message = 'Email/password tidak ditemukan'
                })
        },
    }
}
</script>

<style src="./login.css" scoped></style>
<template>
  <div style="margin-top: 50px; background-color: #f5f6fa">
    <div class="hero">
      <div class="hero-ctn">
        <h1 style="margin: 16px">Portfolio</h1>
        <p style="
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
          ">
          Berbagai proyek kemitraan dalam transformasi digital berkelanjutan
          yang telah berhasil HiColleagues selenggarakan
        </p>
      </div>
    </div>
    <div style="background-color: #f5f6fa">
      <div style="background-color: #f5f6fa">
        <div class="container">
          <div class="d-flex" style="margin-top: 21px; overflow-x: auto; padding-bottom: 20px">
            <div class="inactive" @click="handleChangeCategory(data.value)" :class="{ active: category === data.value }"
              v-for="(data, idx) in categoryList" :key="idx">
              <span>{{ data.name }}</span>
            </div>
          </div>
          <div class="card" style="padding-bottom: 60px">
            <EmptyData v-if="!portfolio || !portfolio.length" message="Belum ada portofolio saat ini" />
            <div class="items" v-for="(item, idx) in portfolio" :key="idx"
              @click="$router.push(`/portfolio/detail/${item.id}`)">
              <div style="height: 240px">
                <img :src="item.image_url" alt="" style="
                    width: 100%;
                    height: 100%;
                    border-radius: 20px 20px 0 0;
                  " />
              </div>
              <div class="content">
                <div style="height: 70px">
                  <h2>{{ item.program }}</h2>
                </div>
                <p class="content-title-p">{{ item.title }}</p>
                <p class="description-class">{{ item.description }}</p>
              </div>
            </div>

            <div v-if="currentPage >= 1 && currentPage <= total" style="display: flex; justify-content: center">
              <Button name="MUAT LAINNYA" width="440px" @click="() => page()" outline="true" outlinecolor="true" />
            </div>
          </div>
        </div>
      </div>
      <v-card elevation="0" class="call-center-container">
        <v-row style="padding: 40px 0px">
          <v-col cols="12">
            <div class="call-center-title">
              Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis
              dalam Transformasi Digital Berkelanjutan Wujudkan Kolaborasi
              Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi
              Digital Berkelanjutan
            </div>
            <div class="call-center-desc">
              Dapatkan berbagai informasi yang kamu butuhkan melalui layanan
              konsultasi 24/7 Customer Service HiColleagues Dapatkan berbagai
              informasi yang kamu butuhkan melalui layanan konsultasi 24/7
              Customer Service HiColleagues
            </div>
          </v-col>
          <v-col cols="12" class="call-center-button" @click="sendWa">
            <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import Button from "../../others/Button.vue";
import EmptyData from "../../others/EmptyData.vue";

export default {
  name: "MobileAlumniStories",
  components: {
    Button,
    EmptyData,
  },
  data() {
    return {
      portfolio: [],
      currentPage: 1,
      perPage: 9,
      total: 1,
      category: "COLLABORATION",
      categoryList: [
        {
          name: "Technology Service",
          value: "SERVICES",
        },
        {
          name: "Corporate Collaboration",
          value: "COLLABORATION",
        },
        {
          name: "Corporate Training",
          value: "TRAINING",
        },
        {
          name: "Talent as a Service",
          value: "TALENT_SERVICE"
        },
      ],
    };
  },
  methods: {
    sendWa() {
      this.$router.push("/corporate");
    },
    page() {
      this.currentPage++;
      this.getPortfolio();
    },
    async getPortfolio(val) {
      if (val) {
        this.category = val;
        this.portfolio = [];
      }
      try {
        const resp = await this.$http.get(
          `/v1/project-portfolio?category=${this.category}&page=${this.currentPage}&per_page=${this.perPage}`
        );
        this.portfolio = resp.data.data.project_portfolios
        if (resp.data.data.total) this.total = parseInt(resp.data.data.total / this.perPage) + 1;
      } catch (err) {
        console.log(err);
      }
    },
    handleChangeCategory(category) {
      this.$router.push(`/portfolio/${category}`);
      window.location.reload()
    }
  },
  mounted() {
    this.category = this.$route.params.category ?? 'SERVICES';
    this.getPortfolio();
  },
};
</script>

<style scoped src="./portfolio.css"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"63px"}},[_vm._m(0),_c('v-row',{staticStyle:{"padding":"70px 102px","background-color":"#F5F6F9"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticStyle:{"padding":"27.5px 30px","border-radius":"20px"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"15px"}},[_vm._l((_vm.program),function(item,idx){return _c('div',{key:idx,class:[
            _vm.selectedProgram == idx ? 'program-active' : '',
            'program',
          ],on:{"click":function($event){return _vm.changeProgram(idx, item.key)}}},[_vm._v(" "+_vm._s(item["name"])+" ")])}),_c('v-btn',{staticClass:"btn-call-b1",attrs:{"block":"","elevation":"0"},on:{"click":_vm.sendToWa}},[_vm._v(" HUBUNGI KAMI ")])],2)])],1),_c('v-col',[(_vm.program[_vm.selectedProgram].sub_program)?_c('v-row',{staticStyle:{"gap":"10px"},attrs:{"no-gutters":""}},_vm._l((_vm.program[_vm.selectedProgram].data),function(item,idx){return _c('v-chip',{key:idx,class:[
            _vm.selectedSubProgram == idx ? 'sub-program-active' : '',
            'sub-program',
          ],on:{"click":function($event){return _vm.changeSubProgram(idx, item.key)}}},[_vm._v(_vm._s(item.name))])}),1):_vm._e(),(!_vm.program[_vm.selectedProgram].sub_program)?_c('v-expansion-panels',{attrs:{"flat":"","accordion":"","multiple":""}},_vm._l((_vm.dataFaq),function(item,idx){return _c('v-expansion-panel',{key:idx,staticClass:"faq-card"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-expansion-panel-content',[_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})])],1)}),1):_vm._e(),(_vm.program[_vm.selectedProgram].sub_program)?_c('v-expansion-panels',{staticStyle:{"margin-top":"30px"},attrs:{"flat":"","accordion":"","multiple":""}},_vm._l((_vm.dataFaq),function(item,idx){return _c('v-expansion-panel',{key:idx,staticClass:"faq-card"},[_c('v-expansion-panel-header',[_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-expansion-panel-content',[_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})])],1)}),1):_vm._e(),(!_vm.dataFaq.length)?_c('empty-data',{attrs:{"message":"Belum ada faq saat ini"}}):_vm._e()],1)],1),_c('v-card',{staticClass:"call-center-container",attrs:{"elevation":"0"}},[_c('v-row',{staticStyle:{"padding":"40px"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"call-center-title"},[_vm._v(" Belum Menemukan Jawaban Seputar Program HiColleagues? ")]),_c('div',{staticClass:"call-center-desc"},[_vm._v(" Dapatkan berbagai informasi yang kamu butuhkan melalui layanan 24/7 Customer Service HiColleagues ")])]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"call-center-button",attrs:{"cols":"4"},on:{"click":_vm.sendToWa}},[_c('v-btn',{staticClass:"btn",attrs:{"elevation":"0"}},[_vm._v(" HUBUNGI KAMI ")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-1"},[_c('div',{staticClass:"text-1"},[_c('div',{staticStyle:{"font-weight":"700","font-size":"40px"}},[_vm._v(" FREQUENTLY ASKED QUESTION ")]),_c('div',{staticStyle:{"font-weight":"400","font-size":"15px"}},[_vm._v(" Temukan beragam informasi yang kamu butuhkan seputar program HiColleagues di sini! ")])])])
}]

export { render, staticRenderFns }
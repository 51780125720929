import { render, staticRenderFns } from "./Testimony.vue?vue&type=template&id=b5b9ad14&scoped=true"
import script from "./Testimony.vue?vue&type=script&lang=js"
export * from "./Testimony.vue?vue&type=script&lang=js"
import style0 from "./css/testimony.css?vue&type=style&index=0&id=b5b9ad14&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5b9ad14",
  null
  
)

export default component.exports
<template>
  <div>
    <event-mobile v-if="isMobile" />
    <event-dekstop v-else />
  </div>
</template>

<script>
import EventDekstop from "@/components/event/desktop/Event.vue";
import EventMobile from "@/components/event/mobile/Event.vue";

export default {
  name: "EventPage",
  components: {
    EventDekstop,
    EventMobile,
  },
  metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.$route.meta.title,
      htmlAttrs: { lang: "id", amp: true },
      meta: [
        { name: "description", content: this.$route.meta.description },
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.$route.meta.title,
            "url": thisPath
          }
        }
      ]
    };
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = window.innerWidth <= 990;
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
};
</script>
<template>
  <div style="padding-top: 50px">
    <img :src="classDetail.image_url" class="img-banner" />
    <div class="detail-body">
      <!-- CONTAINER SECTION -->
      <div class="container">
        <div class="d-flex" style="justify-content: center; flex-wrap: wrap; margin: 20px; gap: 10px">
          <div class="btn-white-oval font-poppins">{{ classDetail.type }}</div>
          <div class="btn-brown-oval font-poppins">{{ classDetail.class_type }}</div>
          <div class="btn-yellow-oval font-poppins">{{ toMethode(classDetail.method) }}</div>
        </div>
        <div class="text-center">
          <h3 class="sub-title-span">
            {{ classDetail.name }}
          </h3>
          <div class="information">
            <h3 class="sub-title-span">
              {{ classDetail.batch }}
            </h3>
            <p v-if="classDetail.schedules.length" style="font-size: 15px">{{
      formatDate(classDetail.schedules[0].start_date) }} - {{
      formatDate(classDetail.schedules[0].end_date) }}</p>
            <div v-if="classDetail.is_discount" style="
                  font-size: 10px;
                  border: 1px solid black;
                  border-radius: 30px;
                  width: fit-content;
                  padding: 4px 12px 4px 12px;
                  margin: auto;
                ">
              Earlybird Promo
            </div>
            <div class="schedule-desc">
              <div class="schedule-content">
                <Star fill="gray" />
                <p class="text"> {{ classDetail.level }}</p>
              </div>
              <div class="schedule-content" v-if="classDetail.schedules.length">
                <person />
                <p class="text">{{ classDetail.schedules[0].total_audience }} Orang</p>
              </div>
              <div class="schedule-content" v-if="classDetail.schedules.length">
                <calendar />
                <p class="text">{{ classDetail.schedules[0].session }} Sesi</p>
              </div>
            </div>
          </div>
          <v-btn depressed color="#FFDE59" @click="goToCheckoutPage()" class="button-daftar" v-if="!isRegistrantClose">
            <span class="text-capitalize font-weight-bold blue-text">DAFTAR SEKARANG</span>
          </v-btn>
          <v-btn depressed color="#FFFFFF" @click="sendToWa" class="button-daftar" style="border: 1px solid">
            <span class="text-capitalize font-weight-bold blue-text">KONSULTASI GRATIS</span>
          </v-btn>
          <div class="collab">
            <p class="text">Kolaborasi Dengan</p>
            <div class="img-collab">
              <img :src="classDetail.collaboration_feed" alt="" width="100" />
            </div>
          </div>
        </div>
        <div class="mt-10">
          <span style="font-size: 14px">Info Kelas</span>
          <h3>Deskripsi Program</h3>
          <p class="desc-program mt-3">{{ classDetail.description }}</p>
        </div>
      </div>
      <div class="bg-container">
        <div style="margin-top: 45px; padding: 40px 0px">
          <span>Materi Silabus</span>
          <h5 class="title mb-4" style="
                font-family: 'Poppins', sans-serif !important;
                line-height: 25px;
              ">
            Materi yang Akan HiColers Pelajari
          </h5>
          <div v-for="(item, idx) in classDetail.summary_syllabus" :key="idx" class="faq-box"
            @click="item.show = !item.show">
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
              <Arrow :down="item.show" fill="#36373f" />
            </div>
            <p v-if="item.show" class="faq-box-active" v-html="item.value"></p>
          </div>
        </div>
      </div>

      <div class="container-parent-grdn radius">
        <!-- Benefit Section -->
        <div class="container">
          <div class="benefits-container">
            <span>Benefit</span>
            <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
              Apa Saja yang Peserta Dapatkan
            </h5>
            <div class="benefits" v-for="(item, idx) in classDetail.benefits" :key="idx">
              <CheckIc :width="26" :height="26" outline fill="#575966" />
              <span>{{ item }}</span>
            </div>
          </div>

          <!-- Tujuan Pelatihan Section -->
          <div class="objectives-container mt-5">
            <span>Tujuan Pelatihan</span>

            <div class="objectives" v-html="classDetail.learning_objective">

            </div>
          </div>

          <!-- Target Section -->
          <div class="target-audience-container mt-5">
            <span>Target Peserta</span>
            <h5 class="title" style="font-family: 'Poppins', sans-serif !important">
              Kelas ini Cocok untuk
            </h5>
            <div class="target" v-for="(item, idx) in classDetail.target_audience" :key="idx">
              <CheckIc :width="26" :height="26" outline fill="#575966" />
              <span>{{ item.level }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <h5 class="title mt-5" style="
              font-family: 'Poppins', sans-serif !important;
              line-height: 25px;
              text-align: center;
            ">
          HiColleagues Pilihan Tepat Akselerasi Skill Digital
        </h5>
        <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
          <div>
            <img :src="item.img" width="70" alt="Ctn" />
          </div>
          <div>
            <h3 style="font-size: 15px">{{ item.title }}</h3>
            <p style="font-size: 12px">{{ item.desc }}</p>
          </div>
        </div>
        <span>Metode Belajar</span>
        <h5 class="title" style="
              line-height: 25px;
              margin-bottom: 20px;
              font-family: 'Poppins', sans-serif !important;
            ">
          Ikuti Bootcamp HiColleagues dengan Metode Belajar yang Konstruktif
        </h5>
        <div v-for="(item, idx) in methodLearning" :key="idx" style="display: flex; gap: 10px; margin-top: 10px">
          <div class="circle-yellow">
            <img :src="item.img" width="28" alt="Ctn" />
          </div>
          <div style="width: 80%">
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
        <br />

        <div class="container-parent mt-10" ref="timeline_belajar">
          <div class="container-detail">
            <span class="addional">Jadwal & Biaya Pelatihan</span>
            <h5 class="title font-poppins" style="font-family: 'Poppins', sans-serif !important">
              Investasi Masa Depan Terjangkau
            </h5>
            <div class="timeline-bootcamp mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
              <div style="padding: 20px">
                <div class="batch-alert d-flex justify-content-between">
                  <p class="batch">Batch {{ item.batch_number }}</p>
                  <div class="alert-early" v-if="classDetail.is_discount">
                    Earlybird Promo
                  </div>
                </div>

                <p class="schedule">
                  {{ formatDate(item.start_date) }} -
                  {{ formatDate(item.end_date) }}
                </p>
              </div>
              <div class="line"></div>
              <div class="info-schadule-top">
                <v-row>
                  <v-col>
                    <div class="info-schadule-ctn">
                      <GrowIc :width="25" fill="#7B7E8C" />
                      <span>{{ classDetail.level }}</span>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="info-schadule-ctn">
                      <DateIc :width="25" fill="#7B7E8C" />
                      <span>{{ item.session }} Sesi</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="info-schadule-ctn">
                      <PeopleIc :width="25" fill="#7B7E8C" />
                      <span>{{ item.total_audience }} Peserta</span>
                    </div>
                  </v-col>

                  <v-col>
                    <div class="info-schadule-ctn">
                      <MethodeIc :width="25" fill="#7B7E8C" />
                      <span>{{
      classDetail.method
        ? toMethode(classDetail.method)
        : "Oflline/Online"
    }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <img src="/img/icons/line-strip.png" alt="Link" style="width: 100%" />
              <div class="info-schadule-bot mb-4" style="display: block">
                <v-row>
                  <v-col>
                    <div class="info-schadule-ctn d-flex">
                      <ClockIc :width="25" fill="#7B7E8C" />
                      <span style="padding-top: -10px">Jam Pelaksanaan</span>
                    </div>
                    <ul class="shadule">
                      <li>
                        <div style="display: flex; gap: 20px; align-items: center">
                          <span class="font-poppins">{{
      classDetail.days
    }}</span>
                          <span>:</span>
                          <span class="font-poppins">{{
        classDetail.hours
      }}</span>
                        </div>
                      </li>
                    </ul>
                  </v-col>
                  <div class="box-price">
                    <div class="early-bird">
                      <div class="price-title-eb" v-if="classDetail.is_discount">
                        <h3 class="eb">{{ classDetail.label_discount }}</h3>
                      </div>
                      <div class="price-title-nm" v-else>
                        <h3 class="np">Normal Price</h3>
                      </div>
                      <div class="price-content">
                        <div class="discount-hide" v-if="classDetail.is_discount">
                          <span style="
                                text-decoration: line-through;
                                color: #b80f0a;
                                font-size: 12px;
                                margin-bottom: 6px;
                              ">{{ classDetail.price | toCurrency }}</span>
                        </div>
                        <h1 v-if="classDetail.is_discount" style="
                              color: #162ca2;
                              font-size: 16px;
                              margin-bottom: 6px;
                            ">
                          {{ toCurrency(classDetail.price - classDetail.discount_price) }}
                        </h1>
                        <h1 style="
                              color: #162ca2;
                              font-size: 16px;
                              margin-bottom: 6px;
                              margin-top: 0px;
                              padding: 0px 0px 20px;
                            " v-else>
                          {{ classDetail.price | toCurrency }}
                        </h1>

                        <span class="sampai-tanggal" v-if="classDetail.is_discount">Berlaku sampai
                          {{ formatDate(classDetail.end_discount_date) }}</span>
                      </div>
                    </div>
                    <p style="text-align: center; margin-top: 6px">
                      Pendaftaran ditutup pada
                      <span style="
                            color: #b80f0a;
                            font-size: 10px;
                            font-weight: 700;
                          ">{{ formatDate(classDetail.registration_close_date) }}</span>
                    </p>
                  </div>

                  <v-col style="display: flex; justify-content: center">
                    <v-btn depressed color="#162CA2" @click="goToCheckoutPage(item)" class="button-daftar" v-if="!isRegistrantClose">
                      <span class="text-capitalize font-weight-bold white-text font-poppins">DAFTAR SEKARANG</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-container" style="margin-bottom: 0px">
        <div style="margin-top: 45px; padding: 40px 0px">
          <h5 class="title mb-4" style="
                font-family: 'Poppins', sans-serif !important;
                line-height: 25px;
                text-align: center;
                font-size: 18px;
              ">
            Frequently Asked Question
          </h5>
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel style="border-radius: 20px; margin-bottom: 10px;" v-for="(item, index) in questions"
              :key="index">
              <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="border-radius: 20px;">
                <h3 style="font-size: 14px; line-height: 20px;">{{ item.title }}</h3>
                <template v-slot:actions>
                  <div class="icon" v-if="item.active" style="margin-right: 20px;">
                    <Arrow />
                  </div>
                  <div v-else>
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius:0 0 20px 20px; margin-left: 10px;">
                <div style="font-size: 12px;" v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <v-btn
            depressed
            color="#FFF"
    
            class="button-faq m-auto"
          >
            <span
              class="text-capitalize font-weight-bold blue-text font-poppins"

              >Lihat Selengkapnya</span
            >
          </v-btn> -->
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CheckIc from "@/assets/icons/Check.vue";
import Star from "@/assets/icons/Star.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import Person from "@/assets/icons/Person.vue";
import Calendar from "@/assets/icons/Calendar.vue";
import ClockIc from "@/assets/icons/Clock.vue";
import MethodeIc from "@/assets/icons/Methode.vue";
import DateIc from "@/assets/icons/Date.vue";
import GrowIc from "@/assets/icons/Grow.vue";
import PeopleIc from "@/assets/icons/People.vue";
export default {
  name: "DetailClassaccelerationMobile",
  components: {
    // Button,
    CheckIc,
    Star,
    Person,
    Calendar,
    Arrow,
    ClockIc,
    MethodeIc,
    DateIc,
    GrowIc,
    PeopleIc,
  },
  data() {
    return {
      whyHiColl: [
        {
          img: "/img/bootcamp/ic_intensive_program.png",
          title: "Intensive Program",
          desc: "Durasi program relatif singkat & mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
        },
        {
          img: "/img/bootcamp/ic_competent_trainer.png",
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_kompetensi.png",
          title: "E-Certificate",
          desc: "Sertifikat sebagai bukti validasi peserta telah menyelesaikan program & diakui oleh industri sebagai nilai pendukung dalam berkarir",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_completion.png",
          title: "Highly  Relevant Case",
          desc: "Pelatihan berbasis 80% praktik & project based learning terhadap kasus yang sangat relevan dalam perkembangan dunia industri digital terkini",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_completion.png",
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
        },
        {
          img: "/img/bootcamp/ic_intensive_program.png",
          title: "Perluas Jangkauan Potensi Karir",
          desc: "Kredibilitas dan sertifikasi yang diperoleh dari program akselerasi dapat membuat peserta menjadi kandidat yang lebih menarik bagi perusahaan-perusahaan ternama.",
        },
      ],
      methodLearning: [
        {
          img: "/img/bootcamp/chat.png",
          title: "Join Community Group",
          desc: "Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.",
        },
        {
          img: "/img/bootcamp/lms.png",
          title: "Access to LMS",
          desc: "Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.",
        },
        {
          img: "/img/bootcamp/sharing.png",
          title: "Sharing Session",
          desc: "Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.",
        },
        {
          img: "/img/bootcamp/project.png",
          title: "Final Project & Exam",
          desc: "Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.",
        },
        {
          img: "/img/bootcamp/zoom.png",
          title: "Live Class via Zoom",
          desc: "Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.",
        },

        {
          img: "/img/bootcamp/partical.png",
          title: "Practical Learning",
          desc: "Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.",
        },
        {
          img: "/img/bootcamp/coumunication.png",
          title: "Live Consultation",
          desc: "Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.",
        },
      ],
      priceAddInfo: [
        "Tipe kelas Public",
        "Kapasitas max 10 peserta",
        "Jadwal kelas telah ditentukan",
        "Diajarkan langsung oleh mentor",
        "Gratis Survival Kit",
        "Sertifikat Kelulusan",
      ],
      classDetail: {
        summary_syllabus: [],
        benefits: [],
        learning_objective: [],
        target_audience: [],
        price: 0,
        is_discount: false,
        discount_price: 0,
        end_discount_date: "",
        schedules: [],
        level: "",
        days: "",
        hours: "",
      },
      alertErrMessage: "",
      isAlertErr: false,
      isRegistrantClose: false,
      questions: [],
      expired: [],
      layout: "info-kelas",
    };
  },
  methods: {
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring (via Zoom)";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      if (value <= 0) {
        return "FREE"
      }

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    goToCheckoutPage(schedule) {
      if (this.isRegistrantClose) {
        this.alertErrMessage = "Pendaftaran sudah di tutup"
        this.isAlertErr = true
        return
      }
      if (!schedule) {
        schedule = this.classDetail.schedules[0]
      }
      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: "ACCELERATION_CLASS",
        type: this.classDetail.type,
        type_class: this.classDetail.class_type,
        is_discount: this.classDetail.is_discount,
        discount_price: this.classDetail.discount_price,
        collaboration_feed: this.classDetail.collaboration_feed,
        schedule: schedule,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });
      localStorage.setItem("checkout", req);
      this.$router.push("/corporate/acceleration/checkout")
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slug_name)
        .then((response) => {
          const data = response.data.data.class_detail;
          this.classDetail = {
            ...data,
            summary_syllabus: data.summary_syllabus
              ? data.summary_syllabus.map((item) => ({
                ...item,
                show: true,
              }))
              : [],
          };
          this.isRegistrantClose = new Date(this.classDetail.registration_close_date) <= new Date()
        })
        .catch((err) => console.error(err));
      // .finally(() => this.spin_load.val = false)
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    setToPoppinsListLearningObjective() {
      const elementLi =
        this.$refs.learning_objective_ctn.getElementsByTagName("li");
      for (let i = 0; i < elementLi.length; i++) {
        elementLi[i].style.fontFamily = "Poppins";
      }
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=CLASS_ACCELERATION&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getDetailClass();
    this.getFaq()
    window.scrollTo(0, 0);
  },
};
</script>
<style src="./detailClassAcceleration.css" scoped></style>
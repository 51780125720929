<template>
  <div style="padding-top: 60px">
    <popup
      :message="alertErrMessage"
      v-if="isAlertErr"
      @close="isAlertErr = false"
    />
    <div>
      <div class="head-class" ref="ctn_head">
        <div class="left">
          <div class="d-flex">
            <div class="btn-white-oval font-poppins">
              {{ classDetail.type }}
            </div>
            <div class="btn-white-oval font-poppins">
              {{ classDetail.class_type }}
            </div>
            <div class="btn-white-oval font-poppins">
              {{ toMethode(classDetail.method) }}
            </div>
          </div>
          <p class="text-banner">{{ classDetail.name }}</p>
          <div class="my-7" v-if="classDetail.schedules.length">
            <div class="head-schedule">
              <div style="gap: 5px" class="pb-2">
                <b>Batch {{ classDetail.schedules[0].batch_number }}</b>
                <span
                  >{{ formatDate(classDetail.schedules[0].start_date) }} -
                  {{ formatDate(classDetail.schedules[0].end_date) }}</span
                >
              </div>
              <div class="line"></div>
              <div style="flex-direction: row">
                <Star :width="25" fill="white" />
                {{ classDetail.level }}
              </div>
              <div style="flex-direction: row">
                <PeopleNew :width="25" fill="white" />
                <span
                  >{{ classDetail.schedules[0].total_audience }} Peserta</span
                >
              </div>
              <div style="flex-direction: row">
                <DateIc2 :width="25" fill="white" />
                <span>{{ classDetail.schedules[0].session }} Sesi</span>
              </div>
            </div>
            <span
              v-if="classDetail.is_discount"
              style="
                border: 1px solid white;
                border-radius: 30px;
                padding: 4px 12px;
              "
              >{{ classDetail.label_discount }}</span
            >
          </div>
          <v-btn
            depressed
            color="#FFDE59"
            @click="goToCheckoutPage()"
            class="button-daftar"
            v-if="!isRegistrantClose"
          >
            <span
              class="text-capitalize font-weight-bold blue-text font-poppins"
              >DAFTAR SEKARANG</span
            >
          </v-btn>
          <v-btn
            depressed
            color="#FFFFFF"
            @click="sendToWa"
            class="button-daftar"
            style="margin-left: 30px"
          >
            <span
              class="text-capitalize font-weight-bold blue-text font-poppins"
              >KONSULTASI GRATIS</span
            >
          </v-btn>
          <div class="collaboration">
            Kolaborasi dengan
            <div class="image">
              <img :src="classDetail.collaboration_feed" alt="" width="50" />
            </div>
          </div>
        </div>
        <img :src="classDetail.image_url" class="img-banner-1" />
      </div>
      <div class="box-radius">
        <div
          v-for="n in menuPublicClass"
          :key="n.id"
          class="menu-detail"
          @click="clickActiveMenu(n.layout, n.position)"
        >
          <span>{{ n.name }}</span>
          <div :class="{ linesmall: layout === n.layout }"></div>
        </div>

        <v-btn
          depressed
          color="#162CA2"
          @click="goToCheckoutPage()"
          style="
            margin-top: 39px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          "
          v-if="!isRegistrantClose"
        >
          <span
            class="text-capitalize font-weight-bold white--text"
            style="font-size: 16px; letter-spacing: 0 !important"
            >DAFTAR SEKARANG</span
          >
        </v-btn>
        <v-btn
          outlined
          color="gray"
          style="
            margin-top: 10px;
            padding: 25px;
            border-radius: 16px;
            width: 100%;
          "
          @click="sendToWa()"
        >
          <span
            class="text-capitalize font-weight-bold"
            style="font-size: 16px; letter-spacing: 0 !important"
            >KONSULTASI GRATIS</span
          >
        </v-btn>
      </div>
      <div class="container-parent" :style="`margin-top: ${classDetail.schedules.length == 0 ? '-490' : '-570'}px`" ref="info_kelas">
        <div class="container-detail">
          <span class="addional">Info Kelas</span>
          <h5
            class="title"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Deskripsi Program
          </h5>
          <p class="desc">
            {{ classDetail.description }}
          </p>
        </div>
      </div>
      <div
        class="bg-container"
        style="margin-bottom: 50px; background: #f1f6ff"
        ref="materi_silabus"
      >
        <div
          class="container-detail"
          style="margin-top: 45px; padding: 40px 0px"
        >
          <span class="addional">Materi Silabus</span>
          <h5
            class="title mb-5"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Materi yang Akan HiColers Pelajari
          </h5>
          <div
            v-for="(item, idx) in classDetail.summary_syllabus"
            :key="idx"
            class="faq-box"
            @click="item.show = !item.show"
          >
            <div class="fax-box-info">
              <p style="width: 90%">{{ item.name }}</p>
              <Arrow :down="item.show" fill="white" />
            </div>
            <p v-if="item.show" v-html="item.value" class="faq-box-active"></p>
          </div>
        </div>
      </div>
      <div class="container-detail" ref="benefit" style="margin-top: 80px">
        <span class="addional">Benefit</span>
        <h5
          class="title mb-5"
          style="font-family: 'Poppins', sans-serif !important"
        >
          Apa Saja yang Peserta Dapatkan
        </h5>
        <div
          class="benefits"
          v-for="(item, idx) in classDetail.benefits"
          :key="idx"
        >
          <CheckIc :width="26" :height="26" outline fill="#575966" />
          <span style="font-family: 'Poppins', sans-serif">{{ item }}</span>
        </div>
      </div>
      <div class="container-parent-grdn" style="padding-bottom: 50px">
        <div
          class="container-detail"
          ref="tujuan_pelatihan"
          style="margin-top: 80px"
        >
          <span class="addional">Tujuan Pelatihan</span>
          <h5
            class="title"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Tujuan Umum
          </h5>
          <div
            class="learning-objective"
            ref="learning_objective_ctn"
            v-html="classDetail.learning_objective"
          ></div>
        </div>
        <div
          class="container-detail"
          ref="taget_peserta"
          style="margin-top: 80px"
        >
          <span class="addional">Target Peserta</span>
          <h5
            class="title"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Kelas ini Cocok untuk
          </h5>
          <ul>
            <li v-for="(item, idx) in classDetail.target_audience" :key="idx">
              {{ item.level }}
            </li>
          </ul>
        </div>
      </div>

      <div
        class="container-parent"
        ref="kenapa_hicolleagues"
        style="margin: 50px 0"
      >
        <div class="container-detail">
          <h5
            class="title"
            style="font-family: 'Poppins', sans-serif !important"
          >
            HiColleagues Pilihan Tepat Akselerasi Skill Digital
          </h5>
          <div class="why-hicoll mt-5">
            <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
              <div>
                <img :src="item.img" width="70" alt="Ctn" />
              </div>
              <div>
                <h3>{{ item.title }}</h3>
                <p style="color: #36373f">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-parent mt-10"
        ref="metode_belajar"
        style="margin: 80px 0"
      >
        <div class="container-detail">
          <span class="addional">Metode Belajar</span>
          <h5
            class="title"
            style="
              font-family: 'Poppins', sans-serif !important;
              line-height: 30px;
            "
          >
            Implementasi Metode Belajar yang Konstruktif
          </h5>
          <div class="why-hicoll mt-8">
            <div v-for="(item, idx) in methodLearning" :key="idx">
              <div class="circle-yellow">
                <img :src="item.img" width="28" alt="Ctn" />
              </div>
              <h3 class="mt-3">{{ item.title }}</h3>
              <p style="color: #36373f">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-parent mt-10" ref="timeline_belajar">
        <div class="container-detail">
          <span class="addional">Timeline Belajar</span>
          <h5
            class="title font-poppins"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Jadwal Pelatihan
          </h5>
          <div
            class="timeline-bootcamp mt-5"
            v-for="(item, idx) in classDetail.schedules"
            :key="idx"
          >
            <div
              style="
                padding: 20px 65px;
                display: flex;
                align-items: center;
                gap: 20px;
              "
            >
              <p class="batch">Batch {{ item.batch_number }}</p>
              <p class="schedule">
                {{ formatDate(item.start_date) }} -
                {{ formatDate(item.end_date) }}
              </p>
              <p class="discount" v-if="classDetail.is_discount">
                {{ classDetail.label_discount }}
              </p>
            </div>
            <div class="line mt-2"></div>
            <div class="info-schadule">
              <div class="info-schadule-ctn">
                <Star :width="30" fill="#7B7E8C" />
                <span>{{ classDetail.level }}</span>
              </div>
              <div class="info-schadule-ctn">
                <PeopleNew :width="30" fill="#7B7E8C" />
                <span>{{ item.total_audience }} Peserta</span>
              </div>
              <div class="info-schadule-ctn">
                <DateIc2 :width="30" fill="#7B7E8C" />
                <span>{{ item.session }} Sesi</span>
              </div>
              <div class="info-schadule-ctn">
                <MethodeNew :width="30" fill="#7B7E8C" />
                <span>{{ classDetail.method | toMethode }}</span>
              </div>
            </div>
            <div class="line mt-4"></div>
            <div class="info-schadule mb-4" style="display: block">
              <v-row>
                <v-col cols="8">
                  <div class="info-schadule-ctn">
                    <ClockIc :width="25" fill="#7B7E8C" />
                    <span style="padding-top: -10px">Jam Pelaksanaan</span>
                  </div>
                  <ul class="shadule">
                    <li>
                      <div
                        style="display: flex; gap: 20px; align-items: center"
                      >
                        <span class="font-poppins">{{ classDetail.days }}</span>
                        <span>:</span>
                        <span class="font-poppins">{{
                          classDetail.hours
                        }}</span>
                      </div>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row class="price-ctn">
                <v-col>
                  <div class="early-bird">
                    <div class="head">
                      <span class="eb">Normal Price</span>
                    </div>
                    <div style="padding: 20px 30px; height: 130px">
                      <h1 style="margin: 0; color: #162ca2">
                        {{ classDetail.price | toCurrency }}
                      </h1>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="early-bird" v-if="classDetail.is_discount">
                    <div class="head" style="background-color: #fef3c7">
                      <span class="eb">Dicount Price</span>
                    </div>
                    <div style="padding: 20px 30px; height: 130px">
                      <p style="color: #b80f0a; font-size: 16px; margin: 0">
                        <del>{{ classDetail.price | toCurrency }}</del>
                      </p>
                      <h1 style="margin: 0; color: #162ca2">
                        {{
                          toCurrency(
                            classDetail.price - classDetail.discount_price
                          )
                        }}
                      </h1>
                      <p style="margin: 0; font-size: 13px">
                        Berlaku
                        {{ formatDate(classDetail.start_discount_date) }} -
                        {{ formatDate(classDetail.end_discount_date) }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="close-date">
                <span
                  >Pendaftaran Ditutup pada
                  <b style="color: #b80f0a">{{
                    formatDate(classDetail.registration_close_date)
                  }}</b></span
                >
                <v-btn
                  depressed
                  color="#162CA2"
                  @click="goToCheckoutPage(item)"
                  class="button-daftar"
                  v-if="!isRegistrantClose"
                >
                  <span
                    class="text-capitalize font-weight-boldfont-poppins"
                    style="color: white"
                    >DAFTAR SEKARANG</span
                  >
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-container"
        style="background: #f1f6ff; border-radius: 80px 80px 0 0"
        ref="faq"
      >
        <div
          class="container-detail"
          style="margin-top: 45px; padding: 40px 0px; min-width: 70%"
        >
          <h5
            class="title mb-5"
            style="font-family: 'Poppins', sans-serif !important"
          >
            Frequently Asked Question
          </h5>
          <div class="col-lg-9" style="margin-top: -10px; min-width: 100%">
            <v-expansion-panels
              flat
              class="faq-card"
              style="padding: 20px; background-color: #f1f6ff"
            >
              <v-expansion-panel
                v-for="(item, index) in questions"
                :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  @click="openFaq(index)"
                >
                  <h2 style="margin: 15px; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow style="margin-right: 35px" />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="margin-left: 15px">
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <!-- <div class="btn-stoke-blue" @click="$router.push('/faq')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import goTo from "vuetify/lib/services/goto";
import Arrow from "@/assets/icons/Arrow.vue";
import CheckIc from "@/assets/icons/Check.vue";
import ClockIc from "@/assets/icons/Clock.vue";
import DateIc2 from "@/assets/icons/DateIc2.vue";
import PeopleNew from "@/assets/icons/PeopleNew.vue";
import Star from "@/assets/icons/Star.vue";
import MethodeNew from "@/assets/icons/MethodeNew.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "DetailClassAccDesktop",
  components: {
    Arrow,
    CheckIc,
    ClockIc,
    DateIc2,
    PeopleNew,
    Star,
    MethodeNew,
    Popup,
  },
  data() {
    return {
      whyHiColl: [
        {
          img: "/img/bootcamp/ic_intensive_program.png",
          title: "Intensive Program",
          desc: "Durasi program relatif singkat & mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_completion.png",
          title: "Highly  Relevant Case",
          desc: "Pelatihan berbasis 80% praktik & project based learning terhadap kasus yang sangat relevan dalam perkembangan dunia industri digital terkini",
        },
        {
          img: "/img/bootcamp/ic_competent_trainer.png",
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
        },
        {
          img: "/img/bootcamp/ic_job_recommentation.png",
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
        },
        {
          img: "/img/bootcamp/ic_sertifikat_kompetensi.png",
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
        },
        {
          img: "/img/bootcamp/ic_installment.png",
          title: "Perluas Jangkauan Potensi Karir",
          desc: "Kredibilitas dan sertifikasi yang diperoleh dari program akselerasi dapat membuat peserta menjadi kandidat yang lebih menarik bagi perusahaan-perusahaan ternama.",
        },
      ],
      methodLearning: [
        {
          img: "/img/bootcamp/chat.png",
          title: "Join Community Group",
          desc: "Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.",
        },
        {
          img: "/img/bootcamp/zoom.png",
          title: "Live Class via Zoom",
          desc: "Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.",
        },
        {
          img: "/img/bootcamp/lms.png",
          title: "Access to LMS",
          desc: "Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.",
        },
        {
          img: "/img/bootcamp/partical.png",
          title: "Practical Learning",
          desc: "Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.",
        },
        {
          img: "/img/bootcamp/sharing.png",
          title: "Sharing Session",
          desc: "Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari trainer ketika sesi pembelajaran berlangsung.",
        },
        {
          img: "/img/bootcamp/coumunication.png",
          title: "Live Consultation",
          desc: "Peserta berkesempatan mendapatkan bimbingan dan mentoring pribadi langsung dari trainer.",
        },
        {
          img: "/img/bootcamp/project.png",
          title: "Final Project & Exam",
          desc: "Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1, position: 0, layout: "info-kelas" },
        {
          name: "Materi Silabus",
          id: 2,
          position: 0,
          layout: "materi-silabus",
        },
        { name: "Benefit", id: 3, position: 0, layout: "benefit" },
        {
          name: "Tujuan Pelatihan",
          id: 4,
          position: 0,
          layout: "tujuan-pelatihan",
        },
        {
          name: "Target Peserta",
          id: 5,
          position: 0,
          layout: "tagret-peserta",
        },
        {
          name: "Kenapa HiColleagues",
          id: 6,
          position: 0,
          layout: "kenapa-hicoleagues",
        },
        {
          name: "Metode Belajar",
          id: 7,
          position: 0,
          layout: "methode-belajar",
        },
        {
          name: "Jadwal & Biaya",
          id: 8,
          position: 0,
          layout: "biaya-pelatihan",
        },
        {
          name: "FAQ",
          id: 9,
          position: 0,
          layout: "faq",
        },
      ],
      methodePembayaran: [
        {
          title: "Pembayaran Pertama",
          type: "(Saat Pendaftaran)",
          list: [
            "<span>Ketika calon peserta mendaftar untuk bootcamp, calon peserta akan diminta untuk</span> membayar pembayaran pertama.",
            "<span>Besaran</span> pembayaran pertama adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Calon peserta akan dianggap</span> resmi terdaftar setelah pembayaran pertama diterima",
          ],
        },
        {
          title: "Pembayaran Kedua",
          type: "(Tengah Training / Senin, 13 November 2023)",
          list: [
            "Pembayaran kedua akan jatuh tempo pada tengah periode pelatihan, <span>yaitu pada tanggal yang telah ditentukan dalam perjanjian pendaftaran.</span>",
            "<span>Besaran</span> pembayaran kedua juga adalah 1/3 dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan</span> diingatkan sebelum jatuh tempo <span>pembayaran kedua</span>",
          ],
        },
        {
          title: "Pembayaran Ketiga",
          type: "(Sebelum Penerimaan Sertifikat)",
          list: [
            "<span>Pembayaran ketiga akan dilakukan setelah calon peserta</span> menyelesaikan seluruh pelatihan dan memenuhi semua persyaratan <span>yang diperlukan.</span>",
            "Pembayaran ketiga adalah sisa dari total harga bootcamp, <span>yaitu sebesar Rp. 2.500.000.</span>",
            "<span>Peserta akan diingatkan</span> sebelum jatuh tempo <span>pembayaran ketiga.</span>",
            "<span>Setelah pembayaran ketiga diterima, peserta akan diberikan</span> sertifikat sebagai bukti kelulusan.",
          ],
        },
      ],
      priceAddInfo: [
        "Tipe kelas Public",
        "Kapasitas max 10 peserta",
        "Jadwal kelas telah ditentukan",
        "Diajarkan langsung oleh mentor",
        "Gratis Survival Kit",
        "Sertifikat Kelulusan",
      ],
      classDetail: {
        summary_syllabus: [],
        benefits: [],
        learning_objective: [],
        target_audience: [],
        price: 0,
        is_discount: false,
        discount_price: 0,
        end_discount_date: "",
        schedules: [],
        level: "",
        days: "",
        hours: "",
      },
      alertErrMessage: "",
      isAlertErr: false,
      isRegistrantClose: false,
      questions: [],
      layout: "info-kelas",
    };
  },
  methods: {
    mappingHeightOfElement() {
      let point = this.$refs.ctn_head.clientHeight;
      this.menuPublicClass[0].position = point;
      point += this.$refs.info_kelas.clientHeight;
      this.menuPublicClass[1].position = point;
      point += this.$refs.materi_silabus.clientHeight;
      this.menuPublicClass[2].position = point;
      point += this.$refs.benefit.clientHeight;
      this.menuPublicClass[3].position = point;
      point += this.$refs.tujuan_pelatihan.clientHeight;
      this.menuPublicClass[4].position = point;
      point += this.$refs.taget_peserta.clientHeight;
      this.menuPublicClass[5].position = point;
      point += this.$refs.kenapa_hicolleagues.clientHeight + 200;
      this.menuPublicClass[6].position = point;
      point += this.$refs.metode_belajar.clientHeight + 220;
      this.menuPublicClass[7].position = point;
      point += this.$refs.timeline_belajar.clientHeight;
      this.menuPublicClass[8].position = point;
      point += this.$refs.faq.clientHeight;
      this.menuPublicClass[9].position = point;
    },
    clickActiveMenu(e, p) {
      this.layout = e;
      goTo(Number(p + 10));
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    goToCheckoutPage(schedule) {
      if (this.isRegistrantClose) {
        this.alertErrMessage = "Pendaftaran sudah di tutup";
        this.isAlertErr = true;
        return;
      }
      if (!schedule) {
        schedule = this.classDetail.schedules[0];
      }
      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: "ACCELERATION_CLASS",
        type: this.classDetail.type,
        type_class: this.classDetail.class_type,
        is_discount: this.classDetail.is_discount,
        discount_price: this.classDetail.discount_price,
        collaboration_feed: this.classDetail.collaboration_feed,
        schedule: schedule,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });
      localStorage.setItem("checkout", req);
      this.$router.push("/corporate/acceleration/checkout");
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slug_name)
        .then((response) => {
          const data = response.data.data.class_detail;
          this.classDetail = {
            ...data,
            summary_syllabus: data.summary_syllabus
              ? data.summary_syllabus.map((item) => ({
                  ...item,
                  show: true,
                }))
              : [],
          };
          this.isRegistrantClose = new Date(this.classDetail.registration_close_date) <= new Date()
          setTimeout(() => {
            this.mappingHeightOfElement();
          }, 1000);
        })
        .catch((err) => console.error(err));
      // .finally(() => this.spin_load.val = false)
    },
    handleScroll() {
      this.menuPublicClass.forEach((item) => {
        if (item.position <= window.scrollY) {
          this.layout = item.layout;
        }
      });
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active;
      this.questions.forEach((e) => {
        e.active = false;
      });
      this.questions[idx].active = !valDef;
    },
    async getFaq() {
      this.questions = [];
      try {
        const resp = await this.$http.get(
          "/v1/faq?category=CLASS_ACCELERATION&is_main=true"
        );
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      if (value <= 0) {
        return "FREE";
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getDetailClass();
    this.getFaq();
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./detailClassAcceleration.css" scoped></style>
<template>
  <div>
      <DekstopAboutUs v-if="!isMobile" />
      <MobileAboutUs v-if="isMobile" />
  </div>
</template>

<script>
import DekstopAboutUs from "@/components/about-us/desktop/AboutUs.vue"
import MobileAboutUs from "@/components/about-us/mobile/AboutUs.vue"

export default {
  components: {
      DekstopAboutUs,
      MobileAboutUs,
  },
  metaInfo() {
    const thisPath = window.location.href
    return {
      title: this.$route.meta.title,
      htmlAttrs: { lang: "id", amp: true },
      meta: [
        { name: "description", content: this.$route.meta.description },
      ],
      link: [
        {
          rel: 'canonical',
          href: thisPath
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": this.$route.meta.title,
            "url": thisPath
          }
        }
      ]
    };
  },
  data() {
      return {
          isMobile: false,
      };
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990;
      },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
      this.handleView();
  },

};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-size bg-success"},[_c('div',{staticClass:"s-card"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"text-center mt-5 d-flex",staticStyle:{"justify-content":"center"}},[_c('Button',{attrs:{"name":"MENGERTI","width":"270px","height":"47px"},on:{"click":() => _vm.goToHomePage()}})],1),_vm._m(3),(_vm.mobileView)?_c('div',[_c('span',[_vm._v("Whatsapp")]),_vm._m(4)]):_c('table',{staticStyle:{"text-align":"left"}},[_vm._m(5)]),_c('div')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"/img/other/ticket_success.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center font-weight-bold mt-10"},[_c('p',{staticStyle:{"font-size":"24px","max-width":"410px","margin":"auto"}},[_vm._v("Pembelian Event Berhasil!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-5 mb-10"},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("Selamat event yang HiColers inginkan telah berhasil dibeli. Silahkan lanjutkan pembayaran terlebih dahulu apa bila event berbayar. Selanjutnya kami akan memberikan akses Zoom untuk bergabung dalam Event, kami kirimkan melalui email. Silahkan cek email di "),_c('b',[_vm._v("inbox / spam")]),_vm._v(" secara berkala.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-10 mb-2"},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("Jika ada pertanyaan lebih lanjut, HiColers dapat menghubungi kami melalui :")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("(+62) 821-2727-4632")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Whatsapp")]),_c('td',[_vm._v(": "),_c('b',[_vm._v("(+62) 821-2727-4632")])])])
}]

export { render, staticRenderFns }
import { render, staticRenderFns } from "./DetailClassAcceleration.vue?vue&type=template&id=14d137c2&scoped=true"
import script from "./DetailClassAcceleration.vue?vue&type=script&lang=js"
export * from "./DetailClassAcceleration.vue?vue&type=script&lang=js"
import style0 from "./detailClassAcceleration.css?vue&type=style&index=0&id=14d137c2&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d137c2",
  null
  
)

export default component.exports
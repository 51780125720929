<template>
  <div>
    <DesktopTechnologyService v-if="!isMobile" />
    <MobileTechnologyService v-if="isMobile" />

  </div>
</template>

<script>
import DesktopTechnologyService from '@/components/corporate/technology-service/desktop/TechnologyService'
import MobileTechnologyService from '@/components/corporate/technology-service/mobile/TechnologyService'
export default {
  components: {
    DesktopTechnologyService,
    MobileTechnologyService
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { name:"keywords", content: "Technology Service,HiColleagues,Technology Service HiColleagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath
            }
          }
        ]
      };
    },
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    handleView() {
      this.isMobile = window.innerWidth <= 990
    }
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
}
</script>